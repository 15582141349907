import Api from '@/services/Api'

export default {
  getAllCompaniesIntistutions(role) {
    let url = (role === 3 || role === 4) ? '/model/company' : '/model/institution'
    url += '?include=true'
    return Api().get(url)
  },
  getStatusList() {
    return Api().get('/model/status?order=id ASC')
  }
}