var backend =  {
  local: 'http://127.0.0.1:3000/',
  development: '',
  staging: '',
  production: 'https://gebiapi.digitalautomations.it/'
}
var frontend = {
  local: 'http://127.0.0.1:8080/',
  development: '',
  staging: '',
  production: 'https://gebi.digitalautomations.it/'
}
module.exports = {
  backend_url: backend.production,
  frontend_url: frontend.production,
  pagination: {
    rows_per_page: 60
  }
}