<template>
  <div id="create-request">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>Crea Richiesta</h3>
          <hr align="left" width="55%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-sm-12 mt-4 mb-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Richiesta creata con successo</span>
          </span>
        </div>
        <b-container fluid v-if="institutions && institutions.length > 0">
          <b-row>
            <b-col>
              <h3>A quale ente vuoi fare richiesta?</h3>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col xs="12" sm="6" offset-sm="3">
              <b-form-select v-model="inst_selected" :options="institution_options" @change="getBenefits()"></b-form-select>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid v-if="benefits && benefits.length > 0" class="mt-4">
          <b-row>
            <b-col>
              <h3>Quale sussidio vuoi richiedere?</h3>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col xs="12" sm="6" offset-sm="3">
              <b-form-select v-model="benefit_selected" :options="benefit_options"></b-form-select>
            </b-col>
          </b-row>
          <b-button  class="mt-4" variant="success" :disabled="!benefit_selected" @click="create_request()">Salva</b-button>
        </b-container>
        <!-- <b-container fluid v-if="benefit" class="mt-4">
          <b-row>
            <h3>{{ benefit.name }}</h3>
          </b-row>
          <b-row>
            <b-col>
              <div v-for="(schema, index) in schemas" :key="index">
                <p>{{ schema.name }}</p>
                <bnvform
                  :key        = "'form_key_'+index"
                  name       = "template"
                  :schema    = "schema.schema"
                  :options   = "{}"
                  :record    = "{name: schema.name}"
                  :submit    = "false"
                  :inline    = "true"
                  :ref       = "'dati_'+index"
                  showexit   = "false"
                  @submitted = "submit"
                ></bnvform>
              </div>
              <b-button variant="success" @click="save()">Salva</b-button>
            </b-col>
          </b-row>
        </b-container> -->
      </b-container>
    </b-container>
  </div>
</template>

<script>
import CompanyService from '@/services/CompanyService'
import BenefitService from '@/services/BenefitService'
import RequestService from '@/services/RequestService'
const meta_converter = require('@/vue-model-mirror/helpers/meta_converter').default;
import bnvform from '@/vue-model-mirror/bnvform';
export default {
  name: 'create-request',
  data() {
    return {
      hasLoaded: false,
      error: null,
      success: false,
      benefits: [],
      benefit_selected: null,
      benefit: null,
      institutions: [],
      inst_selected: null,
      schemas: []
    }
  },
  components: {
    bnvform
  },
  computed: {
    institution_options: function() {
      return [{value: null, text: 'Seleziona un ente'}].concat(
          this.institutions.map(el => {
          var obj = {}
          obj.value = el.id
          obj.text = el.name
          return obj
        })
      )
    },
    benefit_options: function() {
      return [{value: null, text: this.$store.state.user.user.role_id === 4 ? 'Seleziona un sussidio Business' : 'Seleziona un sussidio'}].concat(
          this.benefits.map(el => {
          var obj = {}
          obj.value = el.id
          obj.text = el.name
          return obj
        })
      )
    }
  },
  async mounted() {
    try {
      const institutions = await CompanyService.getInstitutionsByCompany(this.$store.state.user.user.company_id)
      console.log('intitutions', institutions)
      this.institutions = institutions.data.ris
    } catch(err) {
      console.log('err', err)
      this.error = 'An error occurred'
    } finally {
      this.hasLoaded = true
      setTimeout(() => {
        this.error = null
      }, 3000)
    }
    // try {
    //   const benefit = await BenefitService.getBenefit(4)
    //   console.log('benefit', benefit)
    //   this.benefit = benefit.data
    //   Object.keys(benefit.data.template).forEach((item) => {
    //     let data = benefit.data.template[item]
    //     this.schemas.push({...meta_converter.get_schema_simone(data),name:item})
    //   })
    // } catch(err) {
    //   console.log('err', err)
    //   this.error = 'An error occurred'
    // } finally {
    //   this.hasLoaded = true
    //   setTimeout(() => {
    //     this.error = null
    //     this.success = false
    //   }, 3000)
    // }
  },
  methods: {
    async getBenefits() {
      this.hasLoaded = false
      if(!this.inst_selected) {
        this.benefits = []
        this.benefit_selected = null
        // this.benefit = null
        this.hasLoaded = true
        return
      }
      try {
        var business = this.$store.state.user.user.role_id === 4 ? true : false
        const benefits = await BenefitService.getBenefits(this.inst_selected, business)
        console.log('all benefit', benefits)
        this.benefits = benefits.data
      } catch(err) {
        console.log('err', err)
        this.error = 'An error occurred'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.error = null
        }, 3000)
      }
    },
    async create_request() {
      this.hasLoaded = false
      var id_request = null
      try {
        var payload = {}
        payload.institution_id = this.inst_selected
        payload.benefit_id = this.benefit_selected
        const ris = await RequestService.createRequest(payload)
        console.log('ris', ris)
        id_request = ris.data.id
        this.success = true
        setTimeout(() => {
          this.$router.push('/my-request/'+id_request)
        }, 3000)
      } catch(err) {
        console.log('err', err)
        this.error = 'An error occurred'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.error = null
          this.success = false
        }, 3000)
      }
    }
    // async getBenefit() {
    //   this.hasLoaded = false
    //   if (!this.inst_selected) {
    //     this.benefit = null
    //     this.hasLoaded = true
    //     return
    //   }
    //   try {
    //     const benefit = await BenefitService.getBenefit(4)
    //     console.log('benefit', benefit)
    //     this.benefit = benefit.data
    //     Object.keys(benefit.data.template).forEach((item) => {
    //       let data = benefit.data.template[item]
    //       this.schemas.push({...meta_converter.get_schema_simone(data),name:item})
    //     })
    //   } catch(err) {
    //     console.log('err', err)
    //     this.error = 'An error occurred'
    //   } finally {
    //     this.hasLoaded = true
    //     setTimeout(() => {
    //       this.error = null
    //       this.success = false
    //     }, 3000)
    //   }
    // },
    // submit( data ) {
    //   console.log( 'form submitted' );
    //   console.log( data );
    // },
    // save() {
    //   for (let i = 0; i<this.schemas.length; i++) {
    //     let str = 'dati_'+i
    //     console.log(this.$refs[str][0])
    //     this.$refs[str][0].submit_form()
    //   }
    // }
  }
}
</script>

<style>

</style>