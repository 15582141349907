<template>
  <div id="filters" class="text-left">
    <b-row>
      <b-col style="max-width: 150px;">
        <b-button v-b-toggle.collapse-1 variant="primary"><b-icon-funnel-fill></b-icon-funnel-fill> Filtri</b-button>
      </b-col>
      <b-col>
        <CreateReport />
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <p class="card-text"><b>Scegli come filtare le richieste</b></p>
        <div id="form-filter">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-row>
              <b-col>
                <b-form-group
                  description=""
                  label="Seleziona un'azienda"
                  label-for="comapny-form"
                >
                  <b-form-select id="company-form" v-model="form.company" :options="form.company_options" @change="searchUser()"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  description=""
                  label="Seleziona uno stato"
                  label-for="status-form"
                >
                  <b-form-select id="status-form" v-model="form.status" :options="form.status_options"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="form.company">
              <b-col>
              <b-form-group
                description=""
                label="Seleziona un utente"
                label-for="user-form"
              >
              <b-form-select id="user-form" v-model="form.user" :options="form.user_options"></b-form-select>
            </b-form-group>
              </b-col>
            </b-row>
            <!--  -->
            <b-row>
              <b-col>
                <b-form-group
                  description=""
                  label="Seleziona una data di inzio"
                  label-for="date_from-form"
                >
                  <b-form-datepicker id="date_from-form" v-model="form.date.from" :max="form.date.to" class="mb-2" placeholder="Data di inzio" :locale="'it-IT'"></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  description=""
                  label="Seleziona una data di fine"
                  label-for="date_to-form"
                >
                  <b-form-datepicker id="date_to-form" v-model="form.date.to" :min="form.date.from" class="mb-2" placeholder="Data di fine" :locale="'it-IT'"></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button type="submit" variant="primary">Cerca</b-button>
            <b-button type="reset" variant="danger" style="margin-left: 10px;">Reset</b-button>
          </b-form>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import DefaultService from '@/services/DefaultService'
import CompanyService from '@/services/CompanyService'
import UserService from '@/services/UserService'
import CreateReport from '@/components/Admin/CreateReport'
export default {
  name: 'filter',
  components: {
    CreateReport
  },
  data() {
    return {
      hasLoaded: true,
      form: {
        company: null,
        company_options: [
          { value: null, text: 'Seleziona un\' azienda' },
          { value: 1, text: 'Azienda di Simone' },
          { value: 2, text: 'Azienda di Fabio' },
        ],
        date: {
          from: null,
          to: null
        },
        user: null,
        user_options: [
          { value: null, text: 'Seleziona un utente' },
          { value: 1, text: 'Simone Bonfante' },
          { value: 2, text: 'Mario Rossi' }
        ],
        status: 2,
        status_options: []
      },
      show: false
    }
  },
  created () {
    this.$eventHub.$on('select_user', (obj) => {
      console.log(obj.company_id, obj.user_id)
      this.form.company = obj.company_id
      this.searchUser()
        .then(() => {
          this.form.user = obj.user_id
          this.$store.dispatch('request_filters/setRequestFilters', {
            company: this.form.company,
            date: {
              from: this.form.date.from,
              to: this.form.date.to
            },
            user: this.form.user,
            status: this.form.status
          })
          this.$.$emit('filters', true)
        })
        .catch(() => {})
      // 
    })
  },
  beforeDestroy() {
    this.$eventHub.$off('select_user')
  },
  async mounted() {
    this.fillFilters()
    try {
      const status = await DefaultService.getStatusList()
      console.log('status', status)
      this.form.status_options = 
        status.data.map(x => {
          return {value: x.id, text: x.label}
        })
      const companies = await CompanyService.getCompaniesByInstitution(this.$store.state.user.user.institution_id)
      this.form.company_options = [{ value: null, text: 'Seleziona un\'azienda' }].concat(
          companies.data.map(x => {
          return {value: x.id, text: x.name}
        })
      )
      console.log('comp', companies)
    } catch(err) {
      console.log('err', err)
    } finally {
      this.show = true
    }
  },
  methods: {
    async fillFilters() {
      this.form.company = this.$store.state.request_filters.filters.company
      if (this.form.company) {
        await this.searchUser()
      }
      this.form.user = this.$store.state.request_filters.filters.user
      this.form.status = this.$store.state.request_filters.filters.status
      this.form.date.from = this.$store.state.request_filters.filters.date.from
      this.form.date.to = this.$store.state.request_filters.filters.date.to
    },
    async searchUser() {
      if (this.form.company) {
        this.form.user = null
        try {
          const users = await UserService.getUsersByCompany(this.form.company)
          console.log('users', users)
          let default_user = users.data.length > 0 ? [{ value: null, text: 'Seleziona un utente' }] : [{ value: null, text: 'Nessun utente disponibile' }]
          this.form.user_options = default_user.concat(
              users.data.map(x => {
                return {value: x.id, text: x.last_name + ' ' + x.first_name}
              })
            )
        } catch(err) {
          console.log('err', err)
        } finally {
          this.hasLoaded = true
        }
      }

    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$store.dispatch('request_filters/setRequestFilters', {
        company: this.form.company,
        date: {
          from: this.form.date.from,
          to: this.form.date.to
        },
        user: this.form.user,
        status: this.form.status
      })
      this.$.$emit('filters', true)
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.company = null
      this.form.user = null
      this.form.status = 2
      this.form.date.from = null
      this.form.date.to = null
      this.$store.dispatch('request_filters/setRequestFilters', {
        company: this.form.company,
        date: {
          from: this.form.date.from,
          to: this.form.date.to
        },
        user: this.form.user,
        status: this.form.status
      })
      this.$eventHub.$emit('filters', true)
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style>

</style>