<script>

import bnvbase from './bnvbase.vue';
import Vue     from 'vue';
import config_simone from '@/config'
Vue.use( require('vue-filter-pretty-bytes') );
import pdf from 'vue-pdf'

export default {
    data() {
        return {
            config_simone: null
        }
    },
    components: {
        pdf
    },
    created() {
        this.config_simone = config_simone
    },
    extends: bnvbase,
    props: [ 'maxsize', 'mimetypes', ],
    computed: {
        _model () {
            return ( typeof this.model === 'object' && this.model.hasOwnProperty('original_name')  ) ? this.model.original_name : null;
        },
        check_filter () {
            var res = '*/*';
            if ( this.filter === 'images' ) { res = 'image/*'; }
            return res;
        },
    },
    methods: {
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true && this.model === null ) { return true; }
            if ( check === true ) { check = this._validate_maxsize(); }
            if ( check === true ) { check = this._validate_mime_type(); }
            return check;
        },
        _validate_maxsize() {
            var maxsize = 1500000; // default max_size
            if ( this.maxsize && parseInt( this.maxsize ) !== 'NaN' ) { maxsize = parseInt(this.maxsize); }
            if ( this.model.size > maxsize ) {
                this.error_message = `il file pesa ${this.$options.filters.prettyBytes( this.model.size )} (max: ${this.$options.filters.prettyBytes( maxsize )})`;
                this.error_component = true;
                return false;
            }
            return true;
        },
        _validate_mime_type() {
            if ( this._validate_allow_null() === true ) { // TODO devo rifare questo controllo, la funz è da riveder
                var mimetypes = []; // default mime_types (*)
                if ( this.mimetypes && this.mimetypes.hasOwnProperty('length') ) { mimetypes = this.mimetypes; }
                if ( mimetypes.length > 0 && this.model.type && mimetypes.indexOf( this.model.type ) === -1 ) {
                    this.error_message = `la tipologia di file '${this.model.type}' non tra quelli accettati ('${ mimetypes.join('\', \'') }')`;
                    this.error_component = true;
                    return false;
                }
            }
            return true;
        },
        set_file() { // chiamata internamente al variare di input
            this.model = this.$refs[ 'bnv-form-' + this.name ].files[0];
        },
        isImage(url) {
            let ext = url.split(".")
            ext = ext[ext.length-1]
            if (ext === 'pdf' || ext === 'PDF')
                return false
            else
                return true
        }
    },
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name">{{ get_label() }}</label>
        <input
            :accept           = "check_filter"
            :id               = "'bnv-form-' + name"
            :ref              = "'bnv-form-' + name"
            type              = "file"
            class             = "form-control"
            v-bind:class      = "{ 'is-invalid': error_component }"
            v-on:change       = "set_file()"
            :placeholder      = "get_placeholder()"
            :aria-label       = "name"
            :aria-describedby = "name"
            :disabled         = "disabled || _readonly"
        />
        <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted">{{ helper }}</small>
        <small v-if="_model"  class="form-text text-secondary">
            file attualmente salvato:
            <b-img v-if="model.mimetype !== 'image/svg+xml' && isImage(config_simone.backend_url+model.url)" style="max-height: 200px;" alt="" :src="config_simone.backend_url+model.url"></b-img>
            <pdf v-else :src="config_simone.backend_url+model.url" :page="1"></pdf>
            <!-- <b-img v-if="model.mimetype !== 'image/svg+xml'" :src="config_simone.backend_url+model.url" thumbnail fluid rounded="circle" width="35" alt="customer logo"></b-img> {{ _model }} -->
        </small>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>

