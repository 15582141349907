<template>
  <div id="edit-request">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
      </b-container>
      <b-container fluid v-if="benefit" class="mt-4">
        <!-- <b-row>
          <p>{{ benefit.name }}</p>
        </b-row> -->
        <b-row>
          <b-col>
            <div v-for="(schema, index) in schemas" :key="index">
              <h3>{{ schema.name }}</h3>
              <bnvform
                :key       = "'form_key_'+index"
                name       = "template"
                :schema    = "schema.schema"
                :options   = "{}"
                :record    = "can_edit ? request.data ? {...request.data[schema.name], name:schema.name} : {name:schema.name} : null"
                :submit    = "false"
                :inline    = "true"
                :ref       = "'dati_'+index"
                showexit   = "false"
                layout     = "single"
                @submitted = "submit"
              ></bnvform>
            </div>
            <div v-if="request.status_id === 1">
              <b-button variant="success" @click="save(false)">Salva</b-button>
              <b-button style="margin-left: 15px;" variant="info" @click="save(true)">Pubblica</b-button>
            </div>
            <div class="col-sm-12 mt-4 mb-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
              <span style="color:green;" class="col-sm-12 text-center">
                <span>Operazione avvenuta con successo</span>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import BenefitService from '@/services/BenefitService'
import RequestService from '@/services/RequestService'
const meta_converter = require('@/vue-model-mirror/helpers/meta_converter').default;
import bnvform from '@/vue-model-mirror/bnvform';
export default {
  name: 'edit-request',
  props: ['request'],
  data() {
    return {
      hasLoaded: false,
      error: null,
      success: false,
      benefit: null,
      schemas: [],
      data: {
        data: {}
      },
      files: {},
      multipart: false
    }
  },
  components: {
    bnvform
  },
  computed: {
    can_edit: function() {
      // oppure role_id = 3 e = 4
      return this.$store.state.user.user.role_id !== 5 ? true : false
    }
  },
  async mounted() {
    try {
      const benefit = await BenefitService.getBenefit(this.request.benefit_id)
      console.log('benefit', benefit)
      this.benefit = benefit.data
      Object.keys(benefit.data.template).forEach((item) => {
        let data = benefit.data.template[item]
        this.schemas.push({...meta_converter.get_schema_simone(data, this.request.status_id),name:item})
      })
    } catch(err) {
      console.log('err', err)
      this.error = 'An error occurred'
    } finally {
      this.hasLoaded = true
      setTimeout(() => {
        this.error = null
      }, 3000)
    }
  },
  methods: {
    isEmpty(obj) {
      for(var key in obj) {
        if(obj.hasOwnProperty(key))
          return false;
      }
      return true;
    },
    submit( data ) {
      console.log( 'form submitted' );
      console.log( data );
      var name = data.name
      delete data.name
      console.log('SCHEMAs', this.schemas)
      // for (let i = 0; i<Object.keys(data).length; i++) {
      //   let item = Object.keys(data)[i]
      //   // if (data[item] instanceof File) {
      //   if (this.schemas.filter(el => el.schema[item])) {
      //     console.log('file', item, data[item])
      //     this.files[item] = data[item]
      //     data[item] = ''
      //   }
      // }
      let schema = this.schemas.filter(el => el.name === name)[0]
      console.log('schema', schema)
      for (let y = 0; y<Object.keys(schema.schema).length; y++) {
        var item = Object.keys(schema.schema)[y]
        console.log('item', item)
        if (data[item] instanceof File) {
          this.multipart = true
          this.files[item] = data[item] ? data[item] : null
          data[item] = ''
        }
      }
      this.data.data[name] = data
    },
    async save(publish) {
      this.hasLoaded = false
      var is_valid = true
      for (let i = 0; i<this.schemas.length; i++) {
        let str = 'dati_'+i
        this.$refs[str][0].submit_form()
        if (!this.$refs[str][0].is_valid) {
          is_valid = false          
        }
      }
      if (!is_valid) {
        this.hasLoaded = true
        return 0
      }
      try {
        console.log('POST', this.data, this.files)
        var ris;
        var fd = new FormData()
        // if (!this.isEmpty(this.files)) {
        if (this.multipart) {
          fd = await this.fillFd(fd)
          for (let i=0; i<Object.keys(this.files).length; i++) {
            var item = Object.keys(this.files)[i]
            fd.append(item, this.files[item])
          }
        }
        if (publish) {
          ris = this.multipart ?
                await RequestService.publishRequest(fd, this.request.id, this.multipart)
                :
                await RequestService.publishRequest(this.data, this.request.id, this.multipart)
        } else {
          ris = this.multipart ?
                await RequestService.saveRequest(fd, this.request.id, this.multipart)
                :
                await RequestService.saveRequest(this.data, this.request.id, this.multipart)
        }
        console.log('ris', ris)
        this.$eventHub.$emit('request_saved', ris.data.request)
        this.success = true
        location.reload();
      } catch (err) {
        console.log('err', err)
        this.error = publish ? 'Non puoi pubblicare' : 'An error occurred'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.error = null
          this.success = false
        }, 3000)
      }
    },
    fillFd(fd) {
      return new Promise((resolve, reject) => {
        console.log('DATA', this.data)
        // for (let i = 0; i<Object.keys(this.data.data).length; i++) {
        //   var el = Object.keys(this.data.data)[i]
        //   for (let y = 0; y<Object.keys(this.data.data[el]).length; y++) {
        //     var el2 = Object.keys(this.data.data[el])[y]
        //     if (!this.data.data[el][el2]) {
        //       fd.append(`data[${el}][${el2}]`, this.data.data[el][el2])
        //     } else {
        //       if (!this.data.data[el][el2].hasOwnProperty('url'))
        //         fd.append(`data[${el}][${el2}]`, this.data.data[el][el2])
        //       else {
        //         fd.append(`data[${el}][${el2}][url]`, this.data.data[el][el2]['url'])
        //         fd.append(`data[${el}][${el2}][original_name]`, this.data.data[el][el2]['original_name'])
        //       }
        //     }
            
        //   }
        // }
        for (let i = 0; i<Object.keys(this.data.data).length; i++) {
          var el = Object.keys(this.data.data)[i]
          for (let y = 0; y<Object.keys(this.data.data[el]).length; y++) {
            var el2 = Object.keys(this.data.data[el])[y]
            if(this.schemas.filter(x => x.name === el)[0].schema[el2].hasOwnProperty('file')) {
              if (this.data.data[el][el2] !== null) {
                if (!this.data.data[el][el2].hasOwnProperty('url')) {
                  fd.append(`data[${el}][${el2}]`, this.data.data[el][el2])
                }
                else {
                  fd.append(`data[${el}][${el2}][url]`, this.data.data[el][el2]['url'])
                  fd.append(`data[${el}][${el2}][original_name]`, this.data.data[el][el2]['original_name'])
                }
              }
            } else {
              if (this.data.data[el][el2])
                fd.append(`data[${el}][${el2}]`, this.data.data[el][el2])
            }
          }
        }
        resolve(fd)
      }) 
    }
  }
}
</script>

<style>

</style>