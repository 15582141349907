<template>
  <div id="requests-list" v-if="get_my_requets" :key="key">
    <b-table
      class="mt-4 text-left"
      striped
      hover
      id="requestsTable"
      :items="get_my_requets"
      :fields="fields"
      responsive="sm"
      @row-clicked="goTo"
      style="cursor: pointer;"
      :perPage="rows"
      :current-page="currentPage"
    >
      <!-- <template v-slot:cell(nome)="data">
        <span class="td_custom">{{ data.value }}</span>
      </template> -->
      <template v-slot:cell(stato)="data">
        <b-badge style="padding:5px;" :variant="data.value.color">{{ data.value.label }}</b-badge>
      </template>
      <template v-slot:cell(azioni)="data">
        <p v-if="($store.state.user.user.role_id === 3 || $store.state.user.user.role_id === 4) && (data.value.status.id === 1 || data.value.status.id === 2)" class="action" @click="showModal(data.value, 'retreat')"><b-icon-trash></b-icon-trash> Ritira richiesta</p>
        <p v-if="($store.state.user.user.role_id === 5) && (data.value.status.id === 2)" class="action" @click="showModal(data.value, 'draft')"><b-icon-pencil></b-icon-pencil> Metti in bozze</p>
        <p v-if="($store.state.user.user.role_id === 5) && (data.value.status.id === 2)" class="action" @click="showModal(data.value, 'pre-approve')"><b-icon-check2></b-icon-check2> Pre approva</p>
        <p v-if="($store.state.user.user.role_id === 5) && (data.value.status.id === 4)" class="action" @click="showModal(data.value, 'approve')"><b-icon-check2-all></b-icon-check2-all> Approva</p>
        <p v-if="($store.state.user.user.role_id === 5) && (data.value.status.id === 2 || data.value.status.id === 4 || data.value.status.id === 5)" class="action" @click="showModal(data.value, 'reject')"><b-icon-x-circle></b-icon-x-circle> Rifiuta</p>
      </template>
      <template v-slot:cell(reportizzata)="data" v-if="$store.state.user.user.role_id === 5">
        <div v-if="data.value">
          <b class="text-info">Sì</b> <i style="font-size: 12px;" class="text-info"> {{ data.value.split('-').reverse().join('/') }}</i>
        </div>
        <div v-else>
          <b class="text-info">No</b>
        </div>
      </template>
    </b-table>
    <div class="mt-3" v-if="this.get_my_requets && this.get_my_requets.length > 0">
      <b-pagination v-model="currentPage" :total-rows="this.get_my_requets.length" :per-page="rows" aria-controls="requestsTable" align="center"></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'requests-list',
  props: ['my_requests'],
  data() {
    return {
      key: 0,
      currentPage: 1,
      rows: 10,
      fields: [
        { key: 'stato', label: 'Stato', sortable: false },
        { key: 'nome', label: 'Nome', sortable: true },
        { key: 'utente', label: 'Utente', sortable: true },
        { key: 'tipo', label: 'Tipo', sortable: true },
        { key: 'data_creazione', label: 'Data di creazione', sortable: true },
        { key: 'data_modifica', label: 'Ultima data di modifica', sortable: true },
        // { key: 'reportizzata', label: 'Reportizzata', sortable: true },
        // { key: 'azioni', label: 'Azioni', sortable: false }
      ],
    }
  },
  mounted() {
    if (this.$store.state.user.user.role_id === 5) {
      this.fields.push({ key: 'reportizzata', label: 'Report', sortable: true })
    }
  },
  computed: {
    get_my_requets: function() {
      var vm = this
      console.log('richiesta cambiata', this.my_requests)
      return this.my_requests.map(el => {
        let obj = {
          stato: el.status,
          nome: el.benefit.name,
          utente: el.user.first_name + ' ' + el.user.last_name,
          tipo: el.benefit.business ? 'Aziendale' : 'Privata',
          data_creazione: vm.convertDate(el.createdAt),
          data_modifica: vm.convertDate(el.updatedAt),
          reportizzata: el.report_id ? el.report.report_date : null,
          azioni: el
        }
        return obj
      })
    }
  },
  methods: {
    convertDate(inputFormat) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(inputFormat)
      return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    },
    showModal(request, action) {
      this.$eventHub.$emit('show_modal', {request: request, action: action})
    },
    goTo(record, index) {
      var path = '/my-request/'+record.azioni.id
      this.$router.push(path)
    },
    selectUser(user_id, company_id) {
      this.$eventHub.$emit('select_user', { user_id, company_id })
    },
    // rowClass(item, type) {
    //   console.log('item:', item, 'type', type)
    //   return 'table-'+item.azioni.status.color
    // }
  }
}
</script>

<style scoped>
  /* .td_custom {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 1px;
  } */
  tr {
    margin-top: 10px;
    cursor: pointer;
  }
</style>