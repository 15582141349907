<template>
  <div>
    <b-container fluid class="main" v-if="this.$store.state.user.user.role_id === 3 || this.$store.state.user.user.role_id === 4">
      <b-row style="padding: 10px;">
        <h4 style="font-size: 1.3rem;"><i>Richieste Sussidio</i></h4>
      </b-row>
      <b-row
        v-if="this.$store.state.user.user.role_id === 3 ||
              this.$store.state.user.user.role_id === 4"
        :class="$route.path === '/my-requests' ? 'item active' : 'item'"
        @click="goTo('my-requests')"
      >
        <div>
          Le mie richieste
        </div>
      </b-row>
      <b-row
        v-if="this.$store.state.user.user.role_id === 3 ||
              this.$store.state.user.user.role_id === 4"
        :class="$route.path === '/create-request' ? 'item active' : 'item'"
        @click="goTo('create-request')"
      >
        <div>
          Crea richiesta
        </div>
      </b-row>
    </b-container>
    <b-container v-if="$store.state.user.user.role_id === 5" fluid class="main">
      <b-row style="padding: 10px;">
        <h4 style="font-size: 1.3rem;"><i>Richeste Ente</i></h4>
      </b-row>
      <b-row
        :class="$route.path === '/institution-requests' ? 'item active' : 'item'"
        @click="goTo('institution-requests')"
      >
        <div>
          Richieste
        </div>
      </b-row>
      <b-row
        :class="$route.path === '/reports' ? 'item active' : 'item'"
        @click="goTo('reports')"
      >
        <div>
          I miei Report
        </div>
      </b-row>
      <!-- <b-row class="item">
        <div>
          Utenti
        </div>
      </b-row> -->
    </b-container>
    <b-container fluid class="main" style="margin-top: 40px;">
      <b-row style="padding: 10px;">
        <h4 style="font-size: 1.3rem;"><i>Dati personali</i></h4>
      </b-row>
      <b-row :class="$route.path === '/profile' ? 'item active' : 'item'" @click="goTo('profile')">
        <div>
          I miei dati
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'sidebar-company',
  methods: {
    goTo(path) {
      if(this.$route.path !== '/'+path) {
        this.$router.push('/'+path)
      } 
    }
  }
}
</script>

<style scoped>
  .item {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #6666;
    transition: .3s;
  }
  .item:hover {
    background-color: #6666;
    transition: .3s;
  }
  .active {
    background-color: #6666;
  }
  a {
    color: #fff;
  }
</style>