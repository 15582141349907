<template>
  <b-card no-body header="Storico Richiesta" class="sh">
    <b-list-group flush>
      <b-list-group-item v-for="(status, indx) in getStatusHistorySorted" :key="indx">
        <div v-if="canSee(status)" class="list_status">
          <b-row style="padding-left: 10px; padding-right:10px;">
            <i><b>Stato:</b></i><br>
          </b-row>
          <b-row style="padding-left: 10px; padding-right:10px;">
            <span :style="{'color': getStatusColor(status.status_id), 'margin-left': '15px;'}"><i>{{status.status_name}}</i></span>
          </b-row>
          <b-row style="padding-left: 10px; padding-right:10px; margin-top:15px;">
            <i><b>Data e ora:</b></i><br>
          </b-row>
          <b-row style="padding-left: 10px; padding-right:10px;">
            {{ convertDate2(status.date) }}
          </b-row>
          <b-row style="padding-left: 10px; padding-right:10px; margin-top:15px;">
            <i><b>Nota:</b></i><br>
          </b-row>
          <b-row style="padding-left: 10px; padding-right:10px;">
            <span v-if="!status._note">
              Nessuna nota
            </span>
            <ul v-else>
              <div v-if="status._note.errors ? Object.keys(status._note.errors).length === 0 && status._note.errors.constructor === Object ? false  : true : false">
                <li v-for="(note, i) in getStatusHistoryFormatted(status._note.errors)" :key="i">
                  <b>{{ note.message }}</b>: <span style="color: red;" v-for="(error, index) in note.errors" :key="index">{{ error.message }}{{index === note.errors.length - 1 ? '.' : ', '}}</span>
                </li>
              </div>
              <div v-else>
                <li v-if="status._note.errors ? true: false">Nessuna nota</li>
                <li v-else>{{ status._note }}</li>
              </div>
            </ul>
          </b-row>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
export default {
  name: 'status-history',
  props: ['request'],
  data() {
    return {}
  },
  computed: {
    getStatusHistorySorted: function() {
      return this.request.status_history.sort(function(a, b) {
        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      })
    }
  },
  methods: {
    getStatusColor(status_id) {
      switch(status_id) {
        case 1:
          return '#333!important'
        case 2:
          return '#ffc107!important'
        case 3:
          return '#6c757d!important'
        case 4:
          return '#17a2b8!important'
        case 5:
          return '#28a745!important'
        case 6:
          return '#dc3545!important'
        default:
          return 'black'
      }
    },
    convertDate2(date) {
      var date = new Date(date),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hour = ("0" + date.getHours()).slice(-2),
      min = ("0" + date.getMinutes()).slice(-2);
      return [day, mnth, date.getFullYear()].join("/") + ' ' + [hour, min].join(":");
    },
    getStatusHistoryFormatted(errors) {
      let array = []
      for (let i = 0; i<Object.keys(errors).length; i++) {
        var item = Object.keys(errors)[i]
        let obj = {}
        obj.message = item
        obj.errors = []
        for (let y = 0; y<Object.keys(errors[item]).length; y++) {
          var item2 = Object.keys(errors[item])[y]
          let obj2 = {}
          obj2.message = errors[item][item2].message
          obj.errors.push(obj2)
        }
        array.push(obj)
      }
      return array
    },
    canSee(status) {
      if (!status._note) {
        return false
      } else {
        if (!status._note.errors) {
          return false
        } else {
          if (Object.keys(status._note.errors).length === 0 && status._note.errors.constructor === Object) {
            return false
          }
        }
      }
      return true
    }
  }
}
</script>

<style>
  .sh {
    max-height: 1000px;
    overflow-y: auto;
  }
  .list-group-item {
    border: none !important;
    padding: 0 !important;
  }
  .list_status {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
</style>