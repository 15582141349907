<template>
  <div id="my-metas">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>I miei Dati</h3>
          <hr align="left" width="55%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-sm-12 mt-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Operazione avvenuta con successo</span>
          </span>
        </div>
        <div v-for="(schema, index) in schemas" :key="index">
          <h3>{{ schema.name.split('_').join(" ") }}</h3>
          <bnvform
            key        = "form_key"
            name       = "user"
            :schema    = "schema.schema"
            :options   = "{}"
            :record    = "get_meta(schema)"
            :submit    = "false"
            :inline    = "false"
            :ref       = "schema.name"
            showexit   = "false"
            @submitted = "submit"
          ></bnvform>
        </div>
         <!-- <bnvform
        key        = "form_key"
        name       = "user"
        :schema    = "schema"
        :options   = "{}"
        :record    = "{}"
        :submit    = "false"
        :inline    = "false"
        ref        = "form_ref"
        @submitted = "submit"
    ></bnvform> -->
      </b-container>
    </b-container>
  </div>
</template>

<script>
import bnvform from '@/vue-model-mirror/bnvform'
import UserService from '@/services/UserService'
const schemas = require( '@/schemaconfig' )
export default {
  name: 'my-metas',
  data() {
    return {
      hasLoaded: true,
      error: null,
      success: false,
      schemas: []
    }
  },
  components: {
    bnvform
  },
  created () {
    this.schemas.push({schema: schemas.user_meta_dati_anagrafici, name: 'dati_anagrafici'})
    this.schemas.push({schema: schemas.user_meta_indirizzo, name: 'indirizzo'})
  },
  methods: {
    get_meta(schema) {
      console.log('schema', schema)
      return schema.name === 'dati_anagrafici' ? {
          ...this.$store.state.user_meta.user_meta.dati_anagrafici.value,
          id: this.$store.state.user_meta.user_meta.dati_anagrafici.id,
          name: this.$store.state.user_meta.user_meta.dati_anagrafici.name,
          type: this.$store.state.user_meta.user_meta.dati_anagrafici.type
        } : schema.name === 'indirizzo' ? {
          ...this.$store.state.user_meta.user_meta.indirizzo.value,
          id:this.$store.state.user_meta.user_meta.indirizzo.id,
          name: this.$store.state.user_meta.user_meta.indirizzo.name,
          type: this.$store.state.user_meta.user_meta.indirizzo.type
        } : {}
    },
    async submit(data) {
      this.hasLoaded = false
      console.log(data);
      var meta_id = data.id
      var name = data.name
      var type = data.type
      var obj = {}
      delete data.id
      delete data.name
      delete data.type
      try {
        obj = { value: data, name: name, type: type }
        console.log('obj', obj)
        const meta = await UserService.editMeta(obj, meta_id)
        console.log('meta', meta)
        if (meta) {
          var objMeta = {}
          objMeta.name = name
          objMeta.data = meta.data
          this.$store.dispatch('user_meta/setOneMeta', objMeta)
        }
        this.success = true
      } catch(err) {
        console.log('err', err)
        this.error = 'An error occurred'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.success = false
          this.error = null
        }, 3000)
      }
    }
  }
}
</script>

<style>

</style>