<template>
  <div ref="div_pdf" :id="id">
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="url_loaded"
      :page="i"
      style="max-width: 500px;"
      :ref="'pdf_'+i"
      :id="'pdf_'+i"
    ></pdf>
  </div>
</template>
 
<script>
 
import pdf from 'vue-pdf'
import panzoom from 'panzoom'

export default {
  props: ['url', 'id'],
  components: {
    pdf
  },
  data() {
    return {
      url_loaded: '',
      numPages: null
    }
  },
  created() {
    // this.url_loaded = pdf.createLoadingTask('http://127.0.0.1:3000/request_files/29/2f8759cd17f6b14ae089110703bf097df8463b9edb238217c80d03674239e374.pdf')
    this.url_loaded = pdf.createLoadingTask(this.url)
    this.url_loaded.promise.then(pdf => {
      this.numPages = pdf.numPages;
    });
  },
  mounted() {
    var el = document.querySelector('#'+this.id)
    panzoom(el, {
      onDoubleClick: function(e) {
        // `e` - is current double click event.
    
        return false; // tells the library to not preventDefault, and not stop propagation
      },
      beforeWheel: function(e) {
        // allow wheel-zoom only if altKey is down. Otherwise - ignore
        var shouldIgnore = !e.altKey;
        return shouldIgnore;
      }
    })
  },
  methods: {
  }
}
 
</script>

<style scoped>
</style>