<template>
  <div :id="id">
    <b-img :src="src" fluid alt="Responsive image"></b-img>
  </div>
</template>

<script>
import panzoom from 'panzoom'
export default {
  props: ['id', 'src'],
  data() {
    return {}
  },
  mounted() {
    var el = document.querySelector('#'+this.id)
    panzoom(el, {
      onDoubleClick: function(e) {
        // `e` - is current double click event.
    
        return false; // tells the library to not preventDefault, and not stop propagation
      },
      beforeWheel: function(e) {
        // allow wheel-zoom only if altKey is down. Otherwise - ignore
        var shouldIgnore = !e.altKey;
        return shouldIgnore;
      }
    })
  }
}
</script>

<style>

</style>