export const request_filters = {
  namespaced: true,
  state: {
    filters: {
      company: null,
      date: {
        from: null,
        to: null
      },
      user: null,
      status: 2
    }
  },
  mutations: {
    setRequestFilters (state, filters) {
      Object.keys(filters).map(filter => {
        if(filter === 'date') {
          state.filters.date.from = filters.date.from
          state.filters.date.to = filters.date.to
        } else {
          state.filters[filter] = filters[filter]
        }
        return filter
      })
    },
  },
  getters: {
    getFilters: state => {
      return state.filters
    },
  },
  actions: {
    setRequestFilters ({ commit }, filters) {
      commit('setRequestFilters', filters)
    }
  }
}