<template>
  <div>
      <b-overlay
      id="overlay-background"
      :show="!hasLoaded"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
    </b-overlay>
    <b-container fluid class="main">

      <b-alert
        :show="dismissSuccessCountDown"
        dismissible
        fade
        variant="success"
        @dismissed="dismissSuccessCountDown=0"
        @dismiss-count-down="countDownSuccessChanged"
      >
        {{successMessage}}
      </b-alert>
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h2>Registrazione</h2>
          <hr align="left" width="70%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-row class="content">
        <b-col cols="10" xl="6" offset="1" offset-xl="3">
          <b-form-group label-for="email" label-cols-sm="12" label-size="md">
            <template slot="label">Email <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <b-form-input id="email" required :state="validationEmail()" v-model="email" type="email" placeholder="Inserisci l'email"></b-form-input>
          </b-form-group>
          <b-form-group label-for="pwd" label-cols-sm="12" label-size="md">
            <template slot="label">Password <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <b-form-input id="pwd" required :state="validationPassword()" v-model="password" type="password" placeholder="Inserisci la password"></b-form-input><br>
            <b-form-input required :state="validationConfermaPassword()" v-model="conferma_password" type="password" placeholder="Conferma la password"></b-form-input>
          </b-form-group>
          <b-form-group label-for="name" label-cols-sm="12" label-size="md">
            <template slot="label">Nome <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <b-form-input id="name" required :state="validationName()" v-model="name" type="text" placeholder="Inserisci il tuo nome"></b-form-input>
          </b-form-group>
          <b-form-group label-for="surname" label-cols-sm="12" label-size="md">
            <template slot="label">Cognome <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <b-form-input id="surname" required :state="validationSurname()" v-model="surname" type="text" placeholder="Inserisci il tuo cognome"></b-form-input>
          </b-form-group>
          <b-form-group label-for="iban" label-cols-sm="12" label-size="md">
            <template slot="label">Iban <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <b-form-input id="iban" required :state="validationIban()" v-model="iban" type="text" placeholder="Inserisci il tuo iban"></b-form-input>
          </b-form-group>
          <b-form-group label-for="role" label-cols-sm="12" label-size="md">
            <template slot="label">Ruolo <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <!-- <b-form-input id="role" required :state="validationRole()" v-model="role" type="text" placeholder="Inserisci il tuo ruolo"></b-form-input> -->
            <b-form-select id="role" v-model="role.role" :state="validationRole()" :options="role.options" @change="selectRole()"></b-form-select>
          </b-form-group>
          <b-form-group label-for="company" label-cols-sm="12" label-size="md" v-if="role.role === 3 || role.role === 4">
            <template slot="label">Azienda <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <b-form-select id="company" v-model="company.company" :state="validationCompany()" :options="company.options"></b-form-select>
          </b-form-group>
          <b-form-group label-for="institution" label-cols-sm="12" label-size="md" v-if="role.role === 5">
            <template slot="label">Ente <span style="font-size: 12px"><i>(campo obbligatorio)</i></span></template>
            <b-form-select id="institution" v-model="institution.institution" :state="validationInstitution()" :options="institution.options"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row><br>
      <!-- <b-row>
        <b-col cols="10" xl="6" offset="1" offset-xl="3">
          <div class="text-left">
            <h4>Dati Anagrafici</h4>
            <b-form-group label-for="birth_date" label-cols-sm="12" label-size="md">
              <template slot="label">Data di nascita <span style="font-size: 12px"><i>(campo opzionale)</i></span></template>
              <i style="font-size:10px;">AAAA-MM-GG</i>
              <b-input-group class="mb-3">
                <b-form-input
                  id="birth_date"
                  v-model="user_meta.dati_anagrafici.birth_date"
                  type="text"
                  placeholder="AAAA-MM-GG"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="user_meta.dati_anagrafici.birth_date"
                    button-only
                    right
                    locale="en-US"
                    aria-controls="birth_date"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
      </b-row> -->
      <b-row>
        <!-- <UserMetas/> -->
      </b-row>
      <b-row>
        <b-col class="float-left" cols="10" xl="6" offset="1" offset-xl="3">
          <p class="float-left"><router-link to="login">Ho già un account</router-link></p>
        </b-col>
      </b-row>
      <b-alert
        :show="dismissCountDown"
        dismissible
        fade
        variant="danger"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
      >
        {{errorMessage}}
      </b-alert>
      <b-button variant="success" :disabled="checkForm()" @click="register()">Registra</b-button>
    </b-container>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import DefaultService from '@/services/DefaultService'
export default {
  data () {
    return {
      hasLoaded: false,
      errorMessage: '',
      successMessage: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      dismissSuccessCountDown: 0,
      email: '',
      password: '',
      conferma_password: '',
      name: '',
      surname: '',
      iban: '',
      role: {
        role: null,
        options: [
          { value: null, text: 'Scegli un ruolo' },
          { value: 3, text: 'Dipendente di azienda' },
          { value: 4, text: 'Admin azienda' },
          { value: 5, text: 'Ente' }
        ]
      },
      company: {
        company: null,
        options: [
          { value: null, text: 'Scegli un company' }
        ]
      },
      institution: {
        institution: null,
        options: [
          { value: null, text: 'Scegli un ente' }
        ]
      },
      user_meta: {
        dati_anagrafici: {
          first_name: '',
          last_name: '',
          birth_date: null
        },
        indirizzo: {
          via: null,
          n_civico: null,
          città: null,
          provincia: null
        }
      },
      error: null
    }
  },
  components: {
  },
  computed: {
    // getRole: function() {
    //   return (this.role.role === 3 || this.role.role === 4) ?  
    // }
  },
  methods: {
    validationEmail () {
        if (this.email.length > 3 && /.+@.+/.test(this.email))
            return true
        return null
    },
    validationPassword () {
        if (this.password.length > 7)
            return true
        return null
    },
    validationConfermaPassword () {
        if(this.password === this.conferma_password && this.conferma_password.length > 7)
            return true
        return null
    },
    validationName() {
      if (this.name != '')
        return true
      return null
    },
    validationSurname() {
      if (this.surname!= '')
        return true
      return null
    },
    validationIban() {
      if (this.iban!= '')
        return true
      return null
    },
    validationRole() {
      if (this.role.role && this.role.role!= '')
        return true
      return null
    },
    validationCompany() {
      if (this.company.company && this.company.company!= '')
        return true
      return null
    },
    validationInstitution() {
      if (this.institution.institution && this.institution.institution!= '')
        return true
      return null
    },
    checkForm() {
      return (!this.validationEmail() ||
        !this.validationPassword() ||
        !this.validationConfermaPassword() ||
        !this.validationIban() ||
        !this.validationName())
    },
    async selectRole() {
      this.company.options = [{ value: null, text: 'Scegli un\'azienda' }]
      this.institution.options = [{ value: null, text: 'Scegli un ente' }]
      this.company.company = null
      this.institution.institution = null
      try {
        this.hasLoaded = false
        if (this.role.role) {
          let ris = await DefaultService.getAllCompaniesIntistutions(this.role.role)
          console.log('ris role', ris)
          if (this.role.role !== 5) {
            if (ris.data.length === 0) {
              this.company.options = [
                { value: null, text: 'Nessuna azienda esistente' }
              ]
            } else {
              for (let i = 0; i<ris.data.length; i++) {
                let company = ris.data[i]['name']
                console.log('company', company)
                this.company.options.push({ value: ris.data[i].id, text: company })
              }
            }
          } else {
            if (ris.data.length === 0) {
              this.institution.options = [
                { value: null, text: 'Nessun ente esistente' }
              ]
            } else {
              for (let i = 0; i<ris.data.length; i++) {
                this.institution.options.push({ value: ris.data[i].id, text: ris.data[i].name })
              }
            }
          } 
        }
      } catch(err) {
        console.log('err', err)
      } finally {
        this.hasLoaded = true
      }

    },
    async register () {
      try {
        this.hasLoaded = false
        this.user_meta.dati_anagrafici.first_name = this.name
        this.user_meta.dati_anagrafici.last_name = this.surname
        let user = {
          user: {
            email: this.email,
            password: this.password,
            first_name: this.name,
            last_name: this.surname,
            iban: this.iban,
            role_id: this.role.role,
            company_id: this.role.role !== 5 ? this.company.company : null,
            institution_id: this.role.role === 5 ? this.institution.institution : null
          },
          user_meta: {user_meta: this.user_meta}
        }
        const response = await AuthenticationService.register(user)
        if (response) {
          console.log(response)
          //this.$store.dispatch('setToken', response.data.token)
          this.$store.dispatch('user/setUser', response.data)
        }
        this.$router.push({
          name: 'login'
        })
      } catch (error) {
        console.log(error)
        //this.error = error.response.data.error
        this.showAlert('Errore tecnico. Contattare il servizio clienti.')
      } finally {
        this.hasLoaded = true
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    countDownSuccessChanged(dismissCountDown) {
      this.dismissSuccessCountDown = dismissCountDown
    },
    showAlert(msg) {
      this.errorMessage = msg
      this.dismissCountDown = this.dismissSecs
    },
    showSuccess(msg) {
      this.successMessage = msg
      this.dismissSuccessCountDown = this.dismissSecs
    },
  },
  mounted () {
    this.hasLoaded=true
  }
}
</script>

<style scoped>
.main {
    margin-top: 25px;
    padding-left: 8.333333%;
    padding-right: 8.333333%;
    margin-bottom: 25px;
}
.content {
    margin-top: 15px;
}

.form-group {
  text-align: left;
}
</style>
