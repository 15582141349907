import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { user } from '@/store/user.module';
import { user_meta } from '@/store/user_meta.module';
import { template } from '@/store/template.module';
import { request_filters } from '@/store/request_filters.module';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  modules: {
    user,
    user_meta,
    template,
    request_filters
  }
})
