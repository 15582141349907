<script>

import type_manager from './types';
const extras         = require('../../formconfig')
const element_config = require('./element_config').default;

export default {
    name: 'bnvform',
    props: [
        'name',
        'schema',
        'options',
        'record',
        'submit',
        'inline',
        'pk',
        'listpath',
        'layout',
        'focus',
        'showexit',
        'exit',
    ],
    components: {},
    watch: {
        qry_executed(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`${this.name}/force_reload`, true);
                this.$store.dispatch(`${this.name}/executed`, false);
                this.$emit('saved', this.store.obj );
            }
        },
    },
    computed: {
        store () {
            return this.$store.state[ this.name ];
        },
        qry_executed () {
            return this.$store.state[ this.name ].executed;
        },
        _submit () {
            if ( this.submit === false ) {
                return false;
            }
            return true;
        },
        model_label () {
            //return ( extras[ this.name ].hasOwnProperty('_conf') && extras[ this.name ]._conf.hasOwnProperty('label') ) ? extras[ this.name ]._conf.label : this.name;
        },
        form_size () {
            return this.form_settings.hasOwnProperty('size') ? this.form_settings.size : undefined;
        },
        element_class () {
            if ( typeof this.layout === 'undefinded' || this.layout === 'auto' ) { return 2; }
            if ( this.layout === 'single' ) { return 1; }
            return 2;
        },
        setfocus() { return this.focus ? true : false; }
    },
    created () {
        var pre_elements = typeof this.schema === 'function' ? this.schema(type_manager) : this.schema;
        this.elements = {};
        this.elements_column = { 'left': {}, 'right': {}, };
        // layout a una colonna
        Object.keys( pre_elements ).map( x => {
            var column = pre_elements[ x ];
            if (
                   column.type &&
                   [ 'text', 'boolean', 'date', 'integer', 'virt', 'textarea', 'password', 'hidden', ].indexOf( column.type.element_type ) > -1
               ) {
                var frontend_configuration = ( extras.hasOwnProperty( this.name ) && extras[ this.name ].hasOwnProperty( x ) ) ? extras[ this.name ][ x ] : null;
                if ( frontend_configuration === null || frontend_configuration.hasOwnProperty('skip') === false || frontend_configuration.skip === false ) {
                    this.elements[ x ] = column.type;
                    column.edit = false;
                    if ( this.record && this.record[ this.pk ? this.pk : 'id' ] ) { column.edit = true; }
                    if ( this.record && this.record.hasOwnProperty( x ) ) { column.defaultValue = this.record[ x ]; } // in caso di edit
                    this.elements[ x ].set_schema(column, frontend_configuration); // frontend_configuration overrides backend configuration
                }
            }
        });
        // layout a due colonne
        var left_tot = Object.keys( this.elements ).length / 2;
        if ( Number.isInteger( left_tot ) === false ) { left_tot += 0.5; }
        for ( var i = 0; i < Object.keys( this.elements ).length; i++ ) {
            var x = Object.keys( this.elements )[ i ];
            this.elements_column[ i < left_tot  ? 'left' : 'right' ][ x ] = this.elements[ x ];
        }
        // settings generici della form
        this.form_settings = extras.hasOwnProperty( this.name ) && extras[ this.name ].hasOwnProperty('settings') ? extras[ this.name ].settings : {};
    },
    mounted() {
        if ( Object.keys( this.elements ).length > 0 && this.focus ) {
            setTimeout( () => {
                this.$refs['bnvelement_' + Object.keys( this.elements )[0] ][0].$refs.input.focus();
            }, 500 );
        }
    },
    methods: {
        submit_form: function() {
            this.validate();
            if ( this.is_valid === false ) {
                return;
            } else {
                if ( this._submit === true ) {
                    if ( this.has_file === true ) {
                        // form multi-type
                        var data = { schema: this.name, payload: this.form_data, include: true };
                        var pk = this.pk ? this.pk : 'id';
                        if ( this.record && this.record[ pk ] ) {
                            data[ pk ] = this.record[ pk ];
                            this.$store.dispatch(`${this.name}/updatews`, data);
                        } else {
                            this.$store.dispatch(`${this.name}/createws`, data);
                        }
                    } else {
                        var data = { schema: this.name, payload: this.form_data, include: true };
                        var pk = this.pk ? this.pk : 'id';
                        if ( this.record && this.record[ pk ] ) {
                            data[ pk ] = this.record[ pk ];
                            this.$store.dispatch(`${this.name}/update`, data);
                        } else {
                            this.$store.dispatch(`${this.name}/create`, data);
                        }
                    }
                } else {
                    // metto da parte eventuali obj file prima della conversione in json
                    var object_elements = {}
                    Object.keys( this.form_data ).map( x => {
                        if ( this.form_data !== null && typeof this.form_data[ x ] === 'object' ) {
                            object_elements[ x ] = this.form_data[ x ];
                        }
                    });
                    try {
                        var res = JSON.parse( JSON.stringify( this.form_data ) );
                        Object.keys( object_elements ).map( x => { res[ x ] = object_elements[ x ] } );
                        this.$emit('submitted', res);
                        return res;
                    } catch (error) {
                        throw error;
                    }
                }
            }
            //console.log( 'has file :', this.has_file );
            //console.log( 'VALID    :', this.is_valid );
        },
        get_form_data() { // public
            this.validate();
            return JSON.parse( JSON.stringify( this.form_data ) );
        },
        reset_form: function() {
            //this.$store.dispatch('bnvform/clear');
            this.$emit('reset', 1);
        },
        reseta: function() { // public - experimental 14/03/20
            Object.keys( this.elements ).map( element_name => {
                this.$refs[ `bnvelement_${ element_name }` ][0].reseta();
            });
        },
        set_payload(payload) {
            for ( var i = 0; i < Object.keys( payload ).length; i++ ) {
                var element_name = Object.keys( payload )[ i ];
                this.$refs[ `bnvelement_${ element_name }` ][0].set_value( payload[ element_name ] );
            }
        },
        validate() {
            this.is_valid = true;
            Object.keys( this.elements ).map( element_name => {
                var value        =  this.$refs[ `bnvelement_${ element_name }` ][0].get_value();
                var element_type =  this.$refs[ `bnvelement_${ element_name }` ][0].elementtype;
                var is_valid     = !this.$refs[ `bnvelement_${ element_name }` ][0].error_component;
                if ( element_type === 'file' ) { this.has_file = true; }
                if ( is_valid === false ) { this.is_valid = false; }
                this.form_data[ element_name ] = value;
            });
        },
        error_message( name ) {
            if ( this.store.error === true ) {
                if ( this.store.obj && this.store.obj.hasOwnProperty('errors') && this.store.obj.errors.hasOwnProperty( name ) ) {
                    return this.store.obj.errors[ name ].message;
                }
            }
        },
        back_to_list() {
            if ( this.exit !== false ) {
                var list_path = this.listpath || 'list';
                this.$router.push( `/${ list_path }/${this.name}` );
            } else {
                this.$emit('closed', this.store.obj );
            }
        },
        value_changed( payload ) {
            this.$emit('change', payload );
        },
        get_element_config( element ) {
            var conf = new element_config( element );
            //console.log( conf.obj );
            return conf.obj;
        },
        noop() {},
    },
    data () {
        return {
            is_valid  : true,
            form_data : {},
            has_file  : false,
        }
    },
};

</script>

<template>

    <div style="padding: 10px">

        <b-form :inline="false"  v-on:submit.prevent="noop"> <!-- form inline -->
            <b-container fluid="xs">
                <b-row cols="1" :cols-md="element_class">
                    <template v-for="(element, name) in elements">
                        <b-col style="margin: 0; padding: 5px;">
                            <component
                                v-bind:key    = "name"
                                :name         = "name"
                                :ref          = "'bnvelement_' + name"
                                :disabled     = "store.loading"
                                :errormessage = "error_message(name)"
                                :config       = "get_element_config(element)"
                                :is           = "element.component"
                                :elementtype  = "element.element_type"
                                :value        = "element.default_value"
                                :label        = "element.label"
                                :helper       = "element.helper"
                                :placeholder  = "element.placeholder"
                                :store        = "element.store_name"
                                :allownull    = "element.allow_null"
                                :minlength    = "element.min_length"
                                :maxlength    = "element.max_length"
                                :precision    = "element.precision"
                                :decimal      = "element.decimal"
                                :maxsize      = "element.max_size"
                                :maxvalue     = "element.max_value"
                                :mimetypes    = "element.mime_types"
                                :edit         = "element.edit"
                                :readonly     = "element.readonly"
                                :search       = "element.search"
                                :filter       = "element.filter"
                                :size         = "form_size"
                                @change       = "value_changed"
                                @enter_key    = "submit_form"
                            ></component>
                        </b-col>
                    </template>
                </b-row>
                <div style="margin-bottom: 15px; margin-top: 30px;" class="text-center">
                    <button v-if="!inline" style="margin-right: 10px;" type="button" @click="submit_form();" class="btn btn-success">Salva</button>
                    <button v-if="!inline" style="margin-right: 10px;" type="button" @click="reset_form();" class="btn btn-warning">Reset</button>
                    <!-- <button v-if="showexit !== false && !inline" style="margin-right: 10px;" type="button" @click="back_to_list();" class="btn btn-outline-secondary">
                        Esci
                    </button> -->
                    <!-- <button v-if="inline" style="margin-right: 10px;" type="button" @click="submit_form();" class="btn btn-outline-success">Filtra</button> -->
                </div>
            </b-container>
        </b-form>

    </div>

</template>

