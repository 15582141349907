<template>
  <div>
    <b-button v-b-modal.modal-report variant="success" v-b-tooltip.hover title="Crea il report con le richieste pre approvate"><b-icon-card-checklist></b-icon-card-checklist> Crea Report</b-button>
    <b-modal
      id="modal-report"
      title="Crea il report"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Crea"
      cancel-title="Annulla"
      ref="modal-report"
    >
      <!-- <label for="report_date">Seleziona una data del Report</label>
      <b-form-datepicker id="report_date" v-model="report_date" class="mb-2" placeholder="Seleziona una data"></b-form-datepicker> -->
      <!-- <b-button @click="create_report()">Crea</b-button> -->
      <form ref="form" @submit.stop.prevent="create_report">
        <b-form-group
          :state="report_date_state"
          label="Seleziona una data del Report"
          label-for="report_date"
          invalid-feedback="Seleziona una data"
        >
          <b-form-datepicker required :min="new Date(Date.now())" id="report_date" :state="report_date_state" v-model="report_date" class="mb-2" placeholder="Seleziona una data"></b-form-datepicker>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'create-report',
  data() {
    return {
      report_date: null,
      report_date_state: null
    }
  },
  created() {
    this.$eventHub.$on('hide_modal_report', (d) => {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-report')
      })
    })
  },
  beforeDestroy() {
    this.$eventHub.$off('hide_modal_report')
  },
  methods: {
    resetModal() {
      this.report_date = null,
      this.report_date_state = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.create_report(this.report_date)
    },
    create_report(date) {
      if (!this.report_date){
        this.report_date_state = false
        return
      }
      this.$eventHub.$emit('create-report', date)
      this.$nextTick(() => {
        this.$bvModal.hide('modal-report')
      })
    }
  }
}
</script>

<style>

</style>