<template>
  <div id="report-list">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>I miei Report</h3>
          <hr align="left" width="55%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-sm-12 mt-4 mb-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Operazione avvenuta con successo</span>
          </span>
        </div>
      </b-container>
      <b-container fluid class="content mt-4" v-if="reports.length > 0">
        <h3 class="mb-3">I report associati all'ente</h3>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center" href="#" v-for="(report, index) in reports" :key="index" @click="goTo(report.id)">
            Report: {{ report.report_date.split('-').reverse().join('/') }}
            <b-badge variant="primary" pill>{{ report.requests.length }}</b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import ReportService from '@/services/ReportService'
export default {
  name: 'reports-list',
  data() {
    return {
      hasLoaded: false,
      error: null,
      success: false,
      reports: []
    }
  },
  async mounted() {
    try {
      const report_list = await ReportService.getReportsByInstitution(this.$store.state.user.user.institution_id)
      console.log('report list', report_list)
      this.reports = report_list.data
    } catch(err) {
      console.log('err', err)
      this.error = 'An error occurred'
    } finally {
      this.hasLoaded = true
    }
  },
  methods: {
    goTo(id) {
      this.$router.push('/report/'+id)
    }
  }
}
</script>

<style>

</style>