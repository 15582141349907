<template>
    <div>
      <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
        <b-container fluid class="main">
            <b-row id="title">
                <b-col md="3" sm="10" class="text-left">
                    <h2>Accesso</h2>
                    <hr align="left" width="40%" style="border-top: 2px solid rgba(0,0,0,0.7);">
                </b-col>
            </b-row>
            <b-container fluid class="content">
                <b-row>
                    <b-col sm="1" md="4"></b-col>
                    <b-col sm="10" md="4">
                      <p v-if="this.$store.state.user.user" style="color:green;">Registrazione avvenuta con successo. Loggati</p>
                      <b-form-input v-model="email" :state="email_state" type="email" placeholder="Inserisci l'email"></b-form-input><br>
                      <b-form-input v-model="password" :state="password_state" type="password" placeholder="Inserisci la password"></b-form-input>
                    </b-col>
                    <b-col sm="1" md="4"></b-col>
                </b-row><br>
                <b-row>
                    <b-col sm="1" md="4"></b-col>
                    <b-col sm="10" md="4" class="float-left">
                        <p class="float-left"><router-link to="register">Crea un account</router-link></p>
                        <p class="float-right"><span class="forgot_password" @click="reset_password()">Password dimenticata?</span></p>
                    </b-col>
                    <b-col sm="1" md="4">
                    </b-col>
                </b-row>
                <button type="button" class="btn btn-dark" @click="login()">Accedi</button>
            </b-container>
            <b-container fluid class="content" v-if="error">
              <b-row>
                <b-col></b-col>
                <b-col>
                  <p style="color:red;">{{ error }} </p>
                </b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
            <b-container fluid class="content" v-if="error_email">
              <b-row>
                <b-col></b-col>
                <b-col>
                  <p style="color:red;">{{ error_email }} </p>
                </b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          <b-container fluid class="content" v-if="success">
            <b-row>
              <b-col></b-col>
              <b-col>
                <p style="color:green;">{{ success }} </p>
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-container>
          <b-container fluid class="content" v-if="reset">
            <b-row>
              <b-col></b-col>
              <b-col>
                <p style="color:green;"> Successo! Controlla la tua casella di posta </p>
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-container>
        </b-container>
    </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      error: null,
      error_email: null,
      password_state: null,
      email_state: null,
      reset: false,
      success: null,
      hasLoaded: false
    }
  },
  methods: {
    async reset_password () {
      this.email_state = null
      if (this.hasLoaded) {
        this.hasLoaded = false
        this.error = null
        this.error_email = null
        if (this.email === '') {
          this.error_email = "Inserisci l'email!"
          this.email_state = false
          this.hasLoaded = true
        } else {
          try {
            let ris = await AuthenticationService.sendEmailResetPassword({email: this.email})
            if (ris) {
              this.error = null
              this.error_email = null
              this.email_state = null
              this.reset = true
              this.hasLoaded = true
              setTimeout(() => {
                location.reload()  
              }, 2000)
            }
          } catch(error) {
            // this.error_email = "Errore! Contatta l'assistenza tecnica"
            error.response ? this.error_email = error.response.data.message : this.error_email = error + "! Contatta l'assistenza"
            this.hasLoaded = true
            setTimeout(() => {
              this.error = null
              this.error_email = null
            }, 3000)
          }
        }
      }
    },
    async login () {
      this.hasLoaded= false;
      this.error = null
      this.email_state = null
      this.password_state = null
      try {
        const response = await AuthenticationService.login({
          email: this.email,
          password: this.password
        })
        console.log('Login res: ', response)
        this.$store.dispatch('user/setToken', response.data.token) // serve?
        this.$store.dispatch('user/setUser', response.data)
        this.$store.dispatch('user_meta/setUserMeta', response.data.user_meta)
        this.$router.push({
          path: response.data.role_id === 5 ? '/institution-requests' : '/my-requests'
        })
        
      }
      // catch (error) {
      //   console.log(error)
      //   this.error = 'Errore in login'
      //   this.email_state = false
      //   this.password_state = false
      catch (error) {
        console.log(error)
        this.email_state = false
        this.password_state = false
        if (error.response.data.message === "l'utente non è abilitato al login")
          this.error = 'Controlla la tua casella di posta e conferma la registrazione'
        else
          this.error = 'Credenziali non valide'
      } finally {
        this.hasLoaded= true;
      }
    }
  },
  mounted () {
    if (this.$store.state.user.user != null) {
      this.email = this.$store.state.user.user.email
    }
    this.hasLoaded= true;

  }
}
</script>

<style scoped>
.main {
    margin-top: 25px;
    padding-left: 8.333333%;
    padding-right: 8.333333%;
}
.content {
    margin-top: 15px;
}
  .forgot_password {
    cursor:pointer;
  }
.forgot_password:hover {
  color:#007bff;
  text-decoration: underline;
}
</style>
