<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'placeholder', ],
    methods: {},
};

</script>

<template>

    <input
        ref="input"
        type="hidden"
        :id="'bnv-form-' + name"
        v-model="model"
    />

</template>



