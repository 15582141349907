<template>
  <div id="wrapper">
    <b-overlay
        id="overlay-background"
        :show="loading"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main mt-4">
      <b-row>
        <b-col>
          <ChangePassword :title="'Resetta password'" v-if="!loading && tokenValid" :type="'reset'" :token="$route.query.token"/>
          <h2 style="margin-top: 150px; color: #f91942;" v-if="!loading && !tokenValid">Link non valido!</h2>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ChangePassword from '@/components/User/ChangePassword'
import AuthenticationService from '@/services/AuthenticationService'
export default {
  name: 'reset-password',
  data() {
    return {
      loading: true,
      tokenValid: false,
      token: null
    }
  },
  components: {
    ChangePassword
  },
  async mounted() {
    if (!this.$route.query.token) {
      console.log('no token')
      this.tokenValid = false
      setTimeout(() => {
        this.$router.push({
          path: '/'
        })
      }, 3000)
    } else {
      console.log('si token')
      // this.$store.dispatch('user/setToken', this.$route.query.token)
      try {
        let ris = await AuthenticationService.confirmTokenAuthResetPassword(this.$route.query.token)
        if (ris) {
          this.tokenValid = true
        }
      } catch(error) {
        console.log('Token non valido')
        this.tokenValid = false
        // setTimeout(() => {
        //   this.$router.push({
        //     path: '/'
        //   })
        // }, 3000)
      }
    }
    this.loading = false
  }
}
</script>

<style>

</style>