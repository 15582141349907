var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{staticClass:"header",attrs:{"toggleable":"lg","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{attrs:{"href":"#"}},[_vm._v("Gebi")]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[(!this.$store.state.user.isUserLoggedIn)?_c('b-navbar-nav',[_c('b-nav-item',[_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Accedi")])],1),_c('b-nav-item',[_c('router-link',{attrs:{"to":"/register"}},[_vm._v("Registrati")])],1)],1):_vm._e(),_c('b-navbar-nav',{staticClass:"ml-auto"},[(this.$store.state.user.isUserLoggedIn)?_c('b-nav-item-dropdown',{staticClass:"hide_mobile",attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('em',[_vm._v("Ciao, "+_vm._s(_vm.$store.state.user.user.first_name))])]},proxy:true}],null,false,4149156240)},[(this.$store.state.user.user.role_id === 3 || this.$store.state.user.user.role_id === 4)?_c('b-dropdown-item',[(this.$store.state.user.user.role_id === 3 ||
                    this.$store.state.user.user.role_id === 4)?_c('div',{class:_vm.$route.path === '/my-requests' ? 'item active' : 'item',on:{"click":function($event){return _vm.goTo('my-requests')}}},[_c('div',[_vm._v(" Le mie richieste ")])]):_vm._e()]):_vm._e(),(this.$store.state.user.user.role_id === 3 || this.$store.state.user.user.role_id === 4)?_c('b-dropdown-item',[(this.$store.state.user.user.role_id === 3 ||
                    this.$store.state.user.user.role_id === 4)?_c('div',{class:_vm.$route.path === '/create-request' ? 'item active' : 'item',on:{"click":function($event){return _vm.goTo('create-request')}}},[_c('div',[_vm._v(" Crea richiesta ")])]):_vm._e()]):_vm._e(),(_vm.$store.state.user.user.role_id === 5)?_c('b-dropdown-item',[_c('div',{class:_vm.$route.path === '/institution-requests' ? 'item active' : 'item',on:{"click":function($event){return _vm.goTo('institution-requests')}}},[_c('div',[_vm._v(" Le richieste di un ente ")])])]):_vm._e(),(_vm.$store.state.user.user.role_id === 5)?_c('b-dropdown-item',[_c('div',{class:_vm.$route.path === '/reports' ? 'item active' : 'item',on:{"click":function($event){return _vm.goTo('reports')}}},[_c('div',[_vm._v(" I miei Report ")])])]):_vm._e(),_c('b-dropdown-item',[_c('div',{class:_vm.$route.path === '/profile' ? 'item active' : 'item',on:{"click":function($event){return _vm.goTo('profile')}}},[_c('div',[_vm._v(" I miei dati ")])])]),_c('hr'),_c('b-dropdown-item',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")])],1):_vm._e(),(this.$store.state.user.isUserLoggedIn && (
                  this.$store.state.user.user.role_id === 3 ||
                  this.$store.state.user.user.role_id === 4 ))?_c('b-nav-item',{staticClass:"hide_desk",on:{"click":function($event){return _vm.goTo('my-requests')}}},[_c('div',{staticStyle:{"border-bottom":"1px solid #6666","padding-bottom":"10px"}},[_vm._v(" Le mie richieste ")])]):_vm._e(),(this.$store.state.user.isUserLoggedIn && (
                  this.$store.state.user.user.role_id === 3 ||
                  this.$store.state.user.user.role_id === 4 ))?_c('b-nav-item',{staticClass:"hide_desk",on:{"click":function($event){return _vm.goTo('create-request')}}},[_c('div',{staticStyle:{"border-bottom":"1px solid #6666","padding-bottom":"10px"}},[_vm._v(" Crea richiesta ")])]):_vm._e(),(this.$store.state.user.isUserLoggedIn && (
                  this.$store.state.user.user.role_id === 5 ))?_c('b-nav-item',{staticClass:"hide_desk",on:{"click":function($event){return _vm.goTo('institution-requests')}}},[_c('div',{staticStyle:{"border-bottom":"1px solid #6666","padding-bottom":"10px"}},[_vm._v(" Le richieste di un ente ")])]):_vm._e(),(this.$store.state.user.isUserLoggedIn && (
                  this.$store.state.user.user.role_id === 5 ))?_c('b-nav-item',{staticClass:"hide_desk",on:{"click":function($event){return _vm.goTo('reports')}}},[_c('div',{staticStyle:{"border-bottom":"1px solid #6666","padding-bottom":"10px"}},[_vm._v(" I miei Report ")])]):_vm._e(),(this.$store.state.user.isUserLoggedIn)?_c('b-nav-item',{staticClass:"hide_desk",on:{"click":function($event){return _vm.goTo('profile')}}},[_c('div',{staticStyle:{"border-bottom":"1px solid #6666","padding-bottom":"10px"}},[_vm._v(" I miei dati ")])]):_vm._e(),_c('b-nav-item',{staticClass:"hide_desk",on:{"click":function($event){return _vm.goTo('profile')}}},[_c('div',{on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }