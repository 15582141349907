<template>
  <div id="report">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>Report</h3>
          <hr align="left" width="55%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
      </b-container>
      <b-container fluid class="content" v-if="report" id="report-content">
        <b-row>
          <b-col>
             <h2>Report del {{ getDateFormatted(report.report_date) }}</h2>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-table striped hover :items="data_report" :fields="fields_report"></b-table>
          </b-col>
        </b-row>
        <div class="col-sm-12 mt-4 mb-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Operazione avvenuta con successo</span>
          </span>
        </div>
      </b-container>
      <b-row class="mt-4">
        <b-col>
          <b-button variant="info" @click="print()">Stampa</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ReportService from '@/services/ReportService'
export default {
  name: 'report',
  data() {
    return {
      hasLoaded: false,
      error: null,
      success: false,
      report: null
    }
  },
  computed: {
    fields_report: function() {
      let array = []
      let keys = Object.keys(this.report.data[0]).sort(function(a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      for(let i = 0; i<keys.length; i++) {
        array.push({ key: keys[i], sortable: true })
      }
      return array
    },
    data_report: function() {
      return this.report.data
    }
  },
  async mounted() {
    try {
      const report = await ReportService.getReport(this.$route.params.id)
      console.log('report', report)
      this.report = report.data
    } catch(err) {
      console.log('err', err)
      this.error = err
    } finally {
      this.hasLoaded = true
    }
  },
  methods: {
    getDateFormatted(date) {
      return date.split('-').reverse().join("/") 
    },
    print() {
      // var printContents = document.getElementById("report-content").innerHTML;
      // var originalContents = document.body.innerHTML;

      // this.$nextTick(() => {
      //   document.body.innerHTML = printContents;
      //   window.print();
      //   document.body.innerHTML = originalContents;
      //   window.close()
      // });
      const prtHtml = document.getElementById('report-content').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();


    }
  }
}
</script>

<style>

</style>