import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import MyRequests from '@/components/User/MyRequests.vue'
import Request from '@/components/Request/Request.vue'
import MyProfile from '@/components/User/MyProfile.vue'
import ResetPassword from '@/views/ResetPassword'
import InstitutionRequests from '@/components/Admin/InstitutionRequests.vue'
import MyMetas from '@/components/User/MyMetas.vue'
import CreateRequest from '@/components/Request/CreateRequest.vue'
import PDFComponent from '@/components/PDF.vue'
import Report from '@/components/Admin/Report.vue'
import ReportsList from '@/components/Admin/ReportsList.vue'
import store from '@/store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'my-requests',
        component: MyRequests,
        beforeEnter: (to, from, next) => {
          if (store.state.user.user.role_id === 5) {
            next({path: '/institution-requests'})
          } else {
            next()
          }
        }
      },
      {
        path: 'my-request/:id',
        component: Request
      },
      // {
      //   path: 'profile',
      //   component: MyProfile
      // },
      {
        path: 'institution-requests',
        component: InstitutionRequests,
        beforeEnter: (to, from, next) => {
          if (store.state.user.user.role_id === 4 || store.state.user.user.role_id === 3) {
            next({path: '/my-requests'})
          } else {
            next()
          }
        }
      },
      {
        path: 'profile',
        component: MyMetas
      },
      {
        path: 'create-request',
        component: CreateRequest
      },
      {
        path: '/report/:id',
        component: Report
      },
      {
        path: 'reports',
        component: ReportsList
      }
    ],
    beforeEnter: (to, from, next) => {
      if (!store.state.user.isUserLoggedIn) {
        next({path: '/login'})
      } else {
        console.log()
        if (!to.path.includes('/report/') && !to.path.includes('/reports') && !to.path.includes('/create-request') && !to.path.includes('/my-request/') && !to.path.includes('/profile')) {
          if (store.state.user.user.role_id === 5) {
            if (to.path !== '/institution-requests')
              next({path: '/institution-requests'})
            else next()
          } else {
            if (to.path !== '/my-requests')
              next({path: '/my-requests'})
            else {
              next()
            }
          }
        } else {
          next()
        }
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.state.user.isUserLoggedIn) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/pdf',
    component: PDFComponent
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if (store.state.user.isUserLoggedIn) {
        next({path: '/'})
      } else {
        next()
      }
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
  },
  // {
  //   path: '*',
  //   redirect: '/'
  // }
  // {
  //   path: '*',
  //   component: Home,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.state.user.isUserLoggedIn) {
  //       next({path: '/login'})
  //     } else {
  //       next()
  //     }
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
