module.exports = (type) => {
  var schema = {
    first_name: {
      type: type.STRING(1024),
      allowNull: true,
      defaultValue: null,
      label: 'Nome'
    },
    last_name: {
      type: type.STRING(1024),
      allowNull: true,
      defaultValue: null,
      label: 'Cognome'
    },
    birth_date: {
      type: type.DATEONLY,
      allowNull: true,
      defaultValue: null,
      label: 'Data di nascita'
    },
    id: {
      type: type.VIRTUAL,
      allowNull: false,
      hidden: true
    },
    name: {
      type: type.VIRTUAL,
      allowNull: false,
      hidden: true
    },
    type: {
      type: type.VIRTUAL,
      allowNull: false,
      hidden: true
    }
  };

  return schema;
}


