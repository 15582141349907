<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'placeholder', ],
    data () {
        return {             // OK     : il valore viene correttamente sovrascritto in fase di inizializzazione del component (da types.js)
            max_length: 512, // PERÒ   : tutta questa funzione viene sovrscritta
        }                    // QUINDI : se dovessi avere la necessità di usare altri parametri dovrò cambiare modo
    },
    methods: {
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true ) {
                check = this._validate_max_length();
            }
            if ( check === true ) {
                check = this._validate_min_length();
            }
            return check;
        },
        _validate_max_length() {
            if ( this.model.length > this.max_length ) {
                this.error_component = true;
                this.error_message = `lunghezza massima ${this.max_length} caratteri`;
                return false;
            }
            return true;
        },
        _validate_min_length() {
            if ( this.minlength ) {
                if ( this.model.length < this.minlength ) {
                    this.error_component = true;
                    this.error_message = `lunghezza minima ${this.minlength} caratteri`;
                    return false;
                }
            }
            return true;
        }
    },
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}</label>
        <b-input
            ref="input"
            v-on:keyup.enter="enter_key"
            type="password"
            :size="input_class"
            v-bind:class="{ 'is-invalid': error_component }"
            :placeholder="get_placeholder()"
            :aria-label="name"
            :aria-describedby="name"
            :id="'bnv-form-' + name"
            v-model="model"
            :disabled="disabled"
            :readonly="_readonly"
        />
        <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted">{{ helper }}</small>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>



