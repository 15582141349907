<template>
  <div id="my-requests">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>Le mie Richieste</h3>
          <hr align="left" width="65%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-xs-12" v-if="my_requests.length === 0">
          <p>Non hai effettuato ancora una richiesta</p>
        </div>
        <div v-else class="mt-4">
          <b-row class="status_legend">
            <b-col>Legenda degli Stati:</b-col>
            <b-col v-for="status in status_list" :key="status.label">
              <b-row>
                <!-- <b-col sm="2">
                  <span class="dot" :style="{'background-color': getStatusColor(status.color)}"></span>
                </b-col>
                <b-col sm="10">
                  {{ status.label }}
                </b-col> -->
                <b-col style="margin-top:-5px;">
                  <b-badge v-b-tooltip.hover :title="status.description" style="padding:10px; cursor: help;" :variant="status.color">{{ status.label }}</b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="col-sm-12 mt-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
            <span style="color:green;" class="col-sm-12 text-center">
              <span>Operazione avvenuta con successo</span>
            </span>
          </div>
          <!-- <b-card-group deck v-for="(req, indx) in my_requests" :key="indx" class="mt-4">
            <b-card :bg-variant="'light'" :text-variant="''" :header="req.benefit.name" :class="indx !== 0 ? 'text-left mt-3 box' : 'text-left box'" @click="goTo('/my-request/'+req.id)">
              <b-row>
                <b-col sm="4">
                  <b-card :bg-variant="req.status.color" :text-variant="req.status.color === 'light' ? '' : 'white'" :header="req.status.label" :class="indx !== 0 ? 'text-left mt-3' : 'text-left'">
                    <b-card-text><i>{{ req.benefit.description || 'Nessuna descrizione' }}</i></b-card-text>
                    <b-card-text>Data di creazione: {{ convertDate(req.createdAt) }}</b-card-text>
                    <b-card-text>Ultima data di modifica: {{ convertDate(req.updatedAt) }}</b-card-text>
                  </b-card>
                </b-col>
                <b-col sm="8">
                  <b-container fluid>
                    <b-row>
                      <b-col sm="8"></b-col>
                      <b-col sm="4">
                        <p v-if="req.status.id === 1 || req.status.id === 2" class="retreat" @click="showModal(req)"><b-icon-trash></b-icon-trash> Ritira richiesta</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-card>
          </b-card-group> -->
          <RequestsList ref="requests_list" :my_requests="my_requests"/>
        </div>
      </b-container>
      <b-modal ref="retreat-request" size="lg" hide-footer title="Ritira richiesta" @hide="retreat_request = {request_id: null, status_id: null}">
        <div class="d-block text-center">
          <h4>Sei sicuro di voler ritirare la tua richiesta?</h4>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="retreatRequest(retreat_request.request_id, 'cancel')">Annulla Richiesta</b-button>
        <b-button v-if="retreat_request.status_id === 2" class="mt-2" variant="outline-secondary" block @click="retreatRequest(retreat_request.request_id, 'draft')">Metti in bozza</b-button>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import RequestService from '@/services/RequestService'
import DefaultService from '@/services/DefaultService'
import RequestsList from '@/components/RequestsList'
export default {
  name: 'my-requests',
  components: {
    RequestsList
  },
  data() {
    return {
      hasLoaded: false,
      my_requests: [],
      error: null,
      status_list: [],
      retreat_request: {
        request_id: null,
        status_id: null
      },
      success: false
    }
  },
  created () {
    this.$eventHub.$on('show_modal', this.showModal)
  },
  beforeDestroy() {
    this.$eventHub.$off('show_modal')
  },
  async mounted() {
    try {
      let ris = await RequestService.getAllRequest()
      console.log('my request', ris)
      this.my_requests = ris.data
    } catch(err) {
      console.log('err', err)
      this.error = 'An error occurred getting requests' 
      this.hasLoaded = true
    }
    try {
      let status = await DefaultService.getStatusList()
      this.status_list = status.data
    } catch(err) {
      console.log('err', err)
      this.error = 'An error occurred getting status' 
    } finally {
      this.hasLoaded = true
    }
  },
  methods: {
    convertDate(inputFormat) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(inputFormat)
      return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    },
    getStatusColor(variant) {
      switch(variant) {
        case 'light':
          return '#f8f9fa!important'
        case 'warning':
          return '#ffc107!important'
        case 'secondary':
          return '#6c757d!important'
        case 'info':
          return '#17a2b8!important'
        case 'success':
          return '#28a745!important'
        case 'danger':
          return '#dc3545!important'
        default:
          return 'black'
      }
    },
    showModal(data) {
      if (data.action === 'retreat') {
        this.retreat_request.request_id = data.request.id
        this.retreat_request.status_id = data.request.status_id
        this.$refs['retreat-request'].show()
      }
    },
    async retreatRequest(request_id, method) {
      this.hasLoaded = false
      this.$refs['retreat-request'].hide()
      try {
        console.log(request_id, method)
        let ris = await RequestService.retreatRequest(request_id, method)
        if(ris) {
          console.log('ris', ris)
          this.success = true
          for (let i = 0; i<this.my_requests.length; i++) {
            if (this.my_requests[i].id === request_id) {
              this.my_requests[i] = ris.data
              this.$refs['requests_list'].my_requests[i] = ris.data
              this.$refs['requests_list'].key+=1
              break;
            }
          }
        }
      } catch(err) {
        console.log('err', err)
        this.error = 'An error occured retreating request'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.success = false
        }, 3000)
      }
    },
    goTo(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
  @media screen and (max-width: 992px) {
    .status_legend {
      display: none;
    }
  }
  .dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
  }
  .retreat {
    cursor: pointer;
    transition: .3s;
  }
  .retreat:hover {
    color: #f91942;
  }
</style>