<template>
  <div class="home">
    <div class="">
      <div class="container-fluid">
        <div class="row">
            <div class="col-2 px-1 bg-dark position-fixed" id="sticky-sidebar">
                <div class="nav flex-column flex-nowrap vh-100 overflow-auto text-white p-2">
                  <!-- <SideBarCompany v-if="this.$store.state.user.user.role_id === 3 || this.$store.state.user.user.role_id === 4" class="sb_c"/> -->
                  <SideBarCompany class="sb_c"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-10 offset-md-2" id="main">
              <router-view></router-view>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBarCompany from '@/components/User/SideBarC'
import MyRequests from '@/components/User/MyRequests'
export default {
  name: 'home',
  components: {
    SideBarCompany,
    MyRequests
  },
  mounted() {
    // if(this.$store.state.user.user.role_id === 3)
    //   this.$router.push('/my-requests')
    // else if(this.$store.state.user.user.role_id === 5)
    //   this.$router.push('/institution-requests')
  },
  computed: {
    get_routes: function() {
      return 1
    }
  }
}
</script>

<style scoped>
  @media screen and (max-width: 992px) {
    #sticky-sidebar {
      display: none;
    }
    #main {
      margin-left: 0px;
      width: 100%;
    }
  }
  .bg-black {
    background-color: #333 !important;
  }
  #sticky-sidebar {
    border-top: 1px solid #6666;
  }
  .sb_c {
    margin-top: 25px;
  }
  #main {
    padding: 25px;
    max-height: 90vh;
    overflow-y: auto;
  }
</style>
