<template>
  <div id="my-profile">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>Il mio Profilo</h3>
          <hr align="left" width="55%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-sm-12 mt-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Operazione avvenuta con successo</span>
          </span>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'profile',
  data() {
    return {
      hasLoaded: true,
      error: null,
      success: false,
      metas: []
    }
  }
}
</script>

<style>

</style>