import Api from '@/services/Api'

export default {
  getInstitutionsByCompany(company_id) {
    return Api().get(`model/company/${company_id}?include=true`)
  },
  getCompaniesByInstitution(institution_id) {
    return Api().get(`model/company/institution/${institution_id}?include=true`)
  },
  getComapny(company_id) {
    return Api().get(`model/company/${company_id}?include=true`)
  }
}