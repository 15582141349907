<template>
  <div id="institution-requests">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>Richieste</h3>
          <hr align="left" width="45%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-sm-12 mt-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Operazione avvenuta con successo</span>
          </span>
        </div>
        <b-row>
          <b-col md="6" sm="12" class="text-right">
            <span>Richieste in coda <b-badge variant="warning">{{ get_count_pending_request }} <span class="sr-only">unread messages</span></b-badge></span>
          </b-col>
          <b-col md="6" sm="12" class="text-left">
            <span>Richieste Pre approvate <b-badge variant="info">{{ get_count_preapproved_request }} <span class="sr-only">unread messages</span></b-badge></span>
          </b-col>
        </b-row>
        <Filters />
        <RequestsList ref="requests" :my_requests="requests" v-if="requests.length > 0"/>
        <div v-else class="mt-4">
          <b-row>
            <b-col>
              <i>Nessuna richiesta con i filtri selezionati</i>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <!-- <b-modal ref="modal-request" size="lg"  title="Azioni richiesta" @ok="update_status_request(modal_request.action)" @hide="modal_request = {request_id: null, status_id: null, action: ''}">
        <div class="d-block text-center">
          <h4>Sei sicuro di voler eseguire questa Operazione?</h4>
        </div>
        
      </b-modal> -->
      <b-modal
        id="modal-request"
        ref="modal-request"
        title="Aggiornamento Richiesta"
        @hidden="resetModal"
        @hide="resetModal"
        @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <div>
            <p>
              Stai passando lo stato della richiesta a {{ modal_request.action === 'draft' ? 'Bozza' : modal_request.action === 'pre-approve' ? 'Pre Approvata' : modal_request.action === 'approve' ? 'Approvata' :  modal_request.action === 'reject' ?  'Rifiutata' : ''}}
            </p>
          </div>
          <b-form-group
            :state="modal_form.status_note"
            label="Scrivi una nota"
            label-for="note"
            invalid-feedback="Lascia una nota"
          >
            <b-form-input
              id="note"
              v-model="modal_form.note"
              :state="modal_form.status_note"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import RequestService from '@/services/RequestService'
import CompanyService from '@/services/CompanyService'
import ReportService from '@/services/ReportService'
import RequestsList from '@/components/RequestsList'
import Filters from '@/components/Filters'
export default {
  name: 'institution-requests',
  data() {
    return {
      hasLoaded: false,
      error: null,
      success: false,
      requests: [],
      get_count_pending_request: 0,
      get_count_preapproved_request: 0,
      modal_request: {
        request_id: null,
        status_id: null,
        action: ''
      },
      modal_form: {
        note: '',
        status_note: null
      }
    }
  },
  components: {
    RequestsList,
    Filters
  },
  computed: {
    get_filters: function() {
      return this.$store.state.request_filters.filters
    }
  },
  created () {
    this.$eventHub.$on('show_modal', this.showModal)
    this.$eventHub.$on('filters', this.applyFilters)
    this.$eventHub.$on('create-report', this.createReport)
  },
  beforeDestroy() {
    this.$eventHub.$off('show_modal')
    this.$eventHub.$off('filters')
    this.$eventHub.$off('create-report')
  },
  async mounted() {
    try {
      var filters = this.$store.state.request_filters.filters
      const requests = await RequestService.getRequestByInstitution(this.$store.state.user.user.institution_id, filters)
      console.log(requests)
      this.requests = requests.data
      var count = 0
      count = await RequestService.getCountRequest(this.$store.state.user.user.institution_id, 2)
      this.get_count_pending_request = count.data || 0
      count = await RequestService.getCountRequest(this.$store.state.user.user.institution_id, 4)
      this.get_count_preapproved_request = count.data || 0
    } catch(err) {
      console.log('err', err)
      this.error = 'Non hai il permesso di accedere a questa risorsa'
    } finally {
      this.hasLoaded = true
    }
  },
  methods: {
    showModal(data) {
      this.modal_request.request_id = data.request.id
      this.modal_request.status_id = data.request.status_id
      this.modal_request.action = data.action
      this.$refs['modal-request'].show()
    },
    async applyFilters() {
      try {
        this.hasLoaded = false
        var filters = this.$store.state.request_filters.filters
        if (filters.date.from || filters.date.to) {
          if (!filters.date.from) {
            filters.date.from = '1993-03-10'
          }
          if (!filters.date.to) {
            filters.date.to = this.formatDate(Date.now())
          }
        }
        const requests = await RequestService.getRequestByInstitution(this.$store.state.user.user.institution_id, filters)
        console.log(requests)
        this.requests = requests.data
      } catch(err) {
        console.log('err', err)
        this.error = 'An error occurred'
      } finally {
        this.hasLoaded = true
      }
    },
    async createReport(report_date) {
      this.hasLoaded = false
      try {
        const report_requests = await RequestService.gerReportRequests(this.$store.state.user.user.institution_id)
        if (report_requests.data.length === 0) {
          this.error = 'Non ci sono richieste pre approvate per creare un Report!'
          setTimeout(() => {
            this.error = null
          }, 3000)
          return 0
        }
        if (report_requests)
        var requests = report_requests.data
        for (let i = 0; i<requests.length; i++) {
          var company = await CompanyService.getComapny(requests[i].user.company_id)
          requests[i].company = company.data.companyobj
        }
        console.log('FINAL', requests)
        var reported = []
        for (let i = 0; i<requests.length; i++) {
          reported.push({
            "Nome": requests[i].user.last_name + ' ' + requests[i].user.first_name,
            // "Azienda": requests[i].company.company_meta[0].api.value.name,
            "Tipo": requests[i].benefit.business ? 'Aziendale' : 'Privato',
            "Azienda": requests[i].company.name,
            "Sussidio": requests[i].benefit.name,
            "Importo": requests[i].data['Importo'] ? requests[i].data['Importo']['importo'] : 'Nessun importo',
            "Iban": requests[i].user.iban
          })
        }
        console.log('REPORTED', reported)
        let obj = {}
        obj.report = {}
        obj.report.institution_id = this.$store.state.user.user.institution_id
        obj.report.data = reported
        obj.report.report_date = report_date
        obj.requests = requests.map(x => x.id)
        const ris = await ReportService.createReport(obj)
        console.log('ris', ris)
        if (ris) {
          this.$router.push('/report/'+ris.data.report.id)
        }
      } catch(err) {
        console.log('err', err)
        this.error = err
      } finally {
        this.hasLoaded = true
      }
    },
    async update_status_request(bvModalEvt) {
      try {
        bvModalEvt.preventDefault()
        console.log(this.modal_request.action)
      } catch(err) {
        console.log('err', err)
        this.error = 'An error occurred'
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.modal_form.status_note = valid
      return valid
    },
    resetModal() {
      this.modal_request = {request_id: null, status_id: null, action: ''}
      this.modal_form = {note: '', status_note: null}
    },
    handleOk(bvModalEvt) {
      this.hasLoaded = false
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      console.log(this.modal_request.action)
      // Trigger submit handler
      this.handleSubmit(this.modal_request.action, this.modal_request.request_id)
    },
    async handleSubmit(action, request_id) {
      console.log(this.$refs.form.checkValidity())
      // Exit when the form isn't valid
      // if (this.modal_form.note.length < 3){
      //   console.log('sei qui?')
      //   this.modal_form.status_note = false
      //   console.log(this.modal_form.status_note)
      //   return
      // }
      try {
        let status_id = action === 'draft' ? 1 : action === 'pre-approve' ? 4 : action === 'approve' ? 5 : action === 'reject' ? 6 : 2
        const ris = await RequestService.updateStatusrRequest(request_id, {status_id: status_id, note: this.modal_form.note})
        if(ris) {
          console.log('ris', ris)
          this.success = true
          for (let i = 0; i<this.requests.length; i++) {
            if (this.requests[i].id === request_id) {
              this.requests[i] = ris.data
              this.$refs['requests'].my_requests[i] = ris.data
              this.$refs['requests'].key+=1
              break;
            }
          }
        }
      } catch (err) {
        console.log('err',err)
        this.error = 'An error occurred'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.error = null
          this.success = false
        }, 3000)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-request')
        })
      }
      // Hide the modal manually
    },
    formatDate(date) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return [year, month, day].join('-');
    }
  }
  
  
}
</script>

<style>

</style>