<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark" class="header">
      <b-navbar-brand href="#">Gebi</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="!this.$store.state.user.isUserLoggedIn">
          <b-nav-item><router-link to="/login">Accedi</router-link></b-nav-item>
          <b-nav-item><router-link to="/register">Registrati</router-link></b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

          <b-nav-item-dropdown right v-if="this.$store.state.user.isUserLoggedIn" class="hide_mobile">
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em>Ciao, {{ $store.state.user.user.first_name }}</em>
            </template>
            <b-dropdown-item v-if="this.$store.state.user.user.role_id === 3 || this.$store.state.user.user.role_id === 4">
              <div
                v-if="this.$store.state.user.user.role_id === 3 ||
                      this.$store.state.user.user.role_id === 4"
                :class="$route.path === '/my-requests' ? 'item active' : 'item'"
                @click="goTo('my-requests')"
              >
                <div>
                  Le mie richieste
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="this.$store.state.user.user.role_id === 3 || this.$store.state.user.user.role_id === 4">
              <div
                v-if="this.$store.state.user.user.role_id === 3 ||
                      this.$store.state.user.user.role_id === 4"
                :class="$route.path === '/create-request' ? 'item active' : 'item'"
                @click="goTo('create-request')"
              >
                <div>
                  Crea richiesta
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="$store.state.user.user.role_id === 5">
              <div
                :class="$route.path === '/institution-requests' ? 'item active' : 'item'"
                @click="goTo('institution-requests')"
              >
                <div>
                  Le richieste di un ente
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="$store.state.user.user.role_id === 5">
              <div
                :class="$route.path === '/reports' ? 'item active' : 'item'"
                @click="goTo('reports')"
              >
                <div>
                  I miei Report
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div :class="$route.path === '/profile' ? 'item active' : 'item'" @click="goTo('profile')">
                <div>
                  I miei dati
                </div>
              </div>
            </b-dropdown-item>
            <hr>
            <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- FOR MOBILE -->
          <!-- <div right v-if="this.$store.state.user.isUserLoggedIn" class="hide_desk"> -->
            <b-nav-item
              v-if="this.$store.state.user.isUserLoggedIn && (
                    this.$store.state.user.user.role_id === 3 ||
                    this.$store.state.user.user.role_id === 4 )"
              class="hide_desk"
              @click="goTo('my-requests')"
            >
              <div style="border-bottom: 1px solid #6666; padding-bottom: 10px;">
                Le mie richieste
              </div>
            </b-nav-item>
            <b-nav-item
              v-if="this.$store.state.user.isUserLoggedIn && (
                    this.$store.state.user.user.role_id === 3 ||
                    this.$store.state.user.user.role_id === 4 )"
              class="hide_desk"
              @click="goTo('create-request')"
            >
              <div style="border-bottom: 1px solid #6666; padding-bottom: 10px;">
                Crea richiesta
              </div>
            </b-nav-item>
            <b-nav-item
              v-if="this.$store.state.user.isUserLoggedIn && (
                    this.$store.state.user.user.role_id === 5 )"
              class="hide_desk"
              @click="goTo('institution-requests')"
            >
              <div style="border-bottom: 1px solid #6666; padding-bottom: 10px;">
                Le richieste di un ente
              </div>
            </b-nav-item>
            <b-nav-item
              v-if="this.$store.state.user.isUserLoggedIn && (
                    this.$store.state.user.user.role_id === 5 )"
              class="hide_desk"
              @click="goTo('reports')"
            >
              <div style="border-bottom: 1px solid #6666; padding-bottom: 10px;">
                I miei Report
              </div>
            </b-nav-item>
            <b-nav-item
              v-if="this.$store.state.user.isUserLoggedIn"
              class="hide_desk" @click="goTo('profile')"
            >
              <div style="border-bottom: 1px solid #6666; padding-bottom: 10px;">
                I miei dati
              </div>
            </b-nav-item>
            <b-nav-item
              class="hide_desk" @click="goTo('profile')"
            >
              <div @click="logout()">
                Logout
              </div>
            </b-nav-item>
          <!-- </div> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'header',
  data() {
    return {
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/setToken', null)
      this.$store.dispatch('user/setUser', null)
      this.$store.dispatch('user_meta/setUserMeta', null)
      this.$store.dispatch('request_filters/setRequestFilters', {
        company: null,
        date: {
          from: null,
          to: null
        },
        user: null,
        status: 2
      })
      this.$router.push({
        path: '/login'
      })
    },
    goTo(path) {
      if(this.$route.path !== '/'+path) {
        this.$router.push('/'+path)
      } 
    }
  }
}
</script>

<style scoped>
  .header {
    padding-left: 50px;
    padding-right: 50px;
  }
  a {
    text-decoration: none;
    color:rgba(255, 255, 255, 0.5);
    transition: .3s;
  }
  a:hover {
    text-decoration: none;
    color:rgba(255, 255, 255, 0.8);
    transition: .3s;
  }
  @media screen and (max-width: 992px) {
    .hide_mobile {
      display: none;
    }
  }
  @media screen and (min-width: 992px) {
    .hide_desk {
      display: none;
    }
  }
</style>