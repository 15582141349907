module.exports = (type) => {
  var schema = {
    città: {
      type: type.STRING(1024),
      allowNull: true,
      defaultValue: null,
      label: 'Città'
    },
    provincia: {
      type: type.STRING(1024),
      allowNull: true,
      defaultValue: null,
      label: 'Provincia'
    },
    via: {
      type: type.STRING(1024),
      allowNull: true,
      defaultValue: null,
      label: 'Via'
    },
    n_civico: {
      type: type.STRING(1024),
      allowNull: true,
      defaultValue: null,
      label: 'Numero civico'
    },
    id: {
      type: type.VIRTUAL,
      allowNull: false,
      hidden: true
    },
    name: {
      type: type.VIRTUAL,
      allowNull: false,
      hidden: true
    },
    type: {
      type: type.VIRTUAL,
      allowNull: false,
      hidden: true
    }
  };

  return schema;
}