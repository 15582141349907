//////////////////////////////

import { _ } from 'core-js';

const types = require('../bnvform/types').default;


class meta_converter {

    static get_schema(metas, options = { exclude: [] }) {
        var data = { schema: {}, record: {} };
        for ( var i = 0; i < metas.length; i++ ) {
            var meta = metas[ i ];
            if ( meta.type === 'json' ) {
            if ( options.exclude.includes( meta.type )  ) { continue; }
            var post_data = {};
            if ( meta.value.type === 'boolean' ) {
                post_data.type = types.BOOLEAN;
            } else if ( meta.value.type === 'textarea' ) {
                post_data.type = types.TEXT;
            }
            post_data.allowNull = meta.value.hasOwnProperty('allowNull') ? meta.value.allowNull : true;
            if ( meta.value.hasOwnProperty('label') ) { post_data.label = meta.value.label; }
            data.schema[ meta.name ] = post_data;
            data.record[ meta.name ] = meta.value.value;
            }
        }
        return data;
    }
    // get schema simone
    static get_schema_simone(data, status_id) {
        var schemas = { schema: {} }
        for (let i = 0; i<Object.keys(data).length; i++) {
            var item = Object.keys(data)[i]
            var meta = data[item]
            var post_data = {}
            post_data.allowNull = true //meta.hasOwnProperty('allowNull') ? meta.allowNull : true;
            if ( meta.hasOwnProperty('label') ) { post_data.label = meta.label; }
            if ( meta.hasOwnProperty('defaultValue') ) { post_data.defaultValue = meta.defaultValue; }
            if ( meta.hasOwnProperty('file') ) {
                post_data.file = meta.file;
                post_data.max_size = 5000000;
                post_data.mime_types = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]
            }
            if ( meta.hasOwnProperty('readonly') ) {
                if ( status_id !== 1 ) { post_data.readonly = true }
                else { post_data.readonly = meta.readonly }
            } else {
                if ( status_id !== 1 ) { post_data.readonly = true }
            }
            if ( meta.hasOwnProperty('max_value') ) { post_data.max_value = meta.max_value }
            switch(meta.type) {
                case 'string':
                    post_data.type = types.STRING(1024)
                    break;
                case 'integer':
                    post_data.type = types.INTEGER
                    break;
                case 'decimal':
                    post_data.type = types.DECIMAL(10, 2)
                    break;
                case 'boolean' || 'bool':
                    post_data.type = types.BOOLEAN
                    break;
                case 'dateonly':
                    post_data.type = types.DATEONLY
                    break;
                case 'date':
                    post_data.type = types.DATE
                    break;
                // non gestiti a livello backend
                case 'virtual':
                    post_data.type = types.VIRTUAL
                    break;
                case 'now':
                    post_data.type = types.NOW
                    break;
                case 'textarea' || 'text':
                    post_data.type = types.TEXT
                    break;
                case 'password':
                    post_data.type = types.PASSWORD
                    break;
                case 'json':
                    post_data.type = types.JSON
                    break;
                default:
                    post_data.type = types.STRING(1024)
                    break;
            }
            schemas.schema[ item ] = post_data;
        }
        schemas.schema.name = {}
        schemas.schema.name.type = types.VIRTUAL
        schemas.schema.name.allowNull = true
        schemas.schema.name.hidden = true
        return schemas
    }

    static get_schema_with_id(metas, options = { exclude: [] }) {
        var data = meta_converter.get_schema(metas, options);
        var post_data = {
            type      : types.HIDDEN,
            allowNull : false,
        }
        data.schema['id'] = post_data;
        data.record['id'] = metas[0].task_id;
        return data;
    }

}

export default meta_converter;


