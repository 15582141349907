<template>
<div>
  <b-overlay
      id="overlay-background"
      :show="loading"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
  </b-overlay>
  <b-container fluid class="main">
    <b-row>
      <b-col>
        <h4 class="gray">{{ title }}</h4>
      </b-col>
    </b-row>
  </b-container>
  <b-container>
    <b-row>
      <b-col>
        <b-form @submit="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Nuova Password"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="new_password"
              type="password"
              required
              placeholder="Inserisci la password"
              :state="validationNewPassword()"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            label="Conferma nuova Password"
            label-for="input-1"
            description="le due password devono coincidrre"
          >
            <b-form-input
              id="input-1"
              v-model="conferma_password"
              type="password"
              required
              placeholder="Inserisci nuovamente la password"
              :state="validationConfermaPassword()"
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" :disabled="!checkForm()" variant="primary">Cambia Password</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        
        <div class="col-sm-12" v-if="success" style="border: 1px solid green; padding: 20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span v-html="success ? 'Password Aggiornata!' : ''"></span>
          </span>
        </div>
      </b-col>
    </b-row>
  </b-container>

</div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
export default {
  name: 'change-password',
  props: ['title', 'type', 'token'],
  data () {
    return {
      loading: false,
      current_password: '',
      new_password: '',
      conferma_password: '',
      success: false,
      error: null
    }
  },
  methods: {
    // validationCurrentPassword () {
    //     if (this.current_password.length > 3)
    //         return true
    //     return false
    // },
    validationNewPassword () {
      if(this.new_password === '' || this.new_password === null)
        return null
      if (this.new_password.length > 7)
          return true
      return false
    },
    validationConfermaPassword () {
      if(this.conferma_password === '' || this.conferma_password === null)
        return null
      if(this.new_password === this.conferma_password && this.conferma_password.length > 7)
          return true
      return false
    },
    checkForm() {
      return (
        // this.validationCurrentPassword() &&
        this.validationNewPassword() &&
        this.validationConfermaPassword()
      )
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.changePassword()
    },
    async changePassword() {
      this.loading = true
      if (this.type === 'update') {
        try {
          let ris = await AuthenticationService.updatePassword(this.$store.state.user.user.id, {new_password: this.new_password})
          if (ris) {
            console.log('password cambiata')
            this.success = true

            // CI VORRA' UNA DISPATCH IN BASE A COSA TORNA

            setTimeout(() => {
              // location.reload();
              this.new_password = ''
              this.conferma_password= ''
              this.success=false
            }, 2000)
          }
        } catch(error) {
          console.log(error)
          error.response ? this.error = error.response.data.message : this.error = error
          if(!this.error) {
            this.error="Si è verificato un errore nell'aggiornamento della password. Contattare l'assistenza tecnica!"
          }
          setTimeout(() => {
            this.error = null
          }, 3000)
        } finally {
          this.loading = false
        }
      } else if (this.type === 'reset') {
        try {
          let ris = await AuthenticationService.resetPassword(this.token, {new_password: this.new_password})
          if (ris) {
            console.log('password resettata')
            this.success = true

            // QUA NO DISPATCH

            setTimeout(() => {
              // location.reload();
              this.new_password = ''
              this.conferma_password= ''
              this.success=false
              this.$router.push({
                path: '/'
              })
            }, 2000)
          }
        } catch(error) {
          console.log(error)
          error.response ? this.error = error.response.data.message : this.error = error
          if(!this.error) {
            this.error="Si è verificato un errore nel reset della password. Contattare l'assistenza tecnica!"
          }
          setTimeout(() => {
            this.error = null
          }, 3000)
        } finally {
          this.loading = false
        }
      } else {
        this.loading = false
        this.error = 'Nessun type riconosciuto!'
        setTimeout(() => {
          this.error = null
        }, 3000)
      }
    }
  }
}
</script>

<style scoped>
  .is-invalid {
    color: red !important;
    border-color: red !important;
  }
  .is-valid {
    /* color: green !important; */
    border-color: green !important;
    /* background: #e6e6e6 !important; */
  }
  .disabled {
    background: #e6e6e6 !important;
    color: grey !important;
    cursor: not-allowed;
  }
</style>