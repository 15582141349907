<script>

const moment = require('moment');
import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    computed: {
        _model() {
            if ( this.model ) {
                return moment( this.model ).format('YYYY-MM-DD');
            }
            return this.model;
        },
    },
    methods: {
        _return_model() {
            return this._model ? this._model : null;
        },
    },
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}</label>
        <input
            ref="input"
            type="date"
            class="form-control"
            :size="input_class"
            v-bind:class="{ 'is-invalid': error_component }"
            :aria-label="name"
            :aria-describedby="name"
            :id="'bnv-form-' + name"
            v-model="model"
            :disabled="disabled"
            :readonly="_readonly"
        />
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>



