export const user_meta = {
  namespaced: true,
  state: {
    user_meta: null,
  },
  mutations: {
    setUserMeta (state, user_meta) {
      state.user_meta = user_meta
    },
    setOneMeta (state, meta) {
      state.user_meta[meta.name] = meta.data
    }
  },
  actions: {
    setUserMeta ({ commit }, user_meta) {
      commit('setUserMeta', user_meta)
    },
    setOneMeta ({ commit }, meta) {
      commit('setOneMeta', meta)
    },
  }
}