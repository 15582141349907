<template>
  <div id="read-request">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container fluid class="main">
      <b-container fluid class="content">
        <div class="col-sm-12" v-if="error" style="border: 1px solid red; padding :20px; border-radius: 4px;">
          <span style="color:red;" class="col-sm-12 text-center">
            <span v-html="error"></span>
          </span>
        </div>
        <div class="col-sm-12 mt-4 mb-4" v-if="success" style="border: 1px solid green; padding :20px; border-radius: 4px;">
          <span style="color:green;" class="col-sm-12 text-center">
            <span>Operazione avvenuta con successo</span>
          </span>
        </div>
      </b-container>
      <b-container fluid class="content">
        <b-row>
          <b-col :cols="Object.keys(get_files).length > 0 ? 6 : 8">
            <b-row id="title1">
              <b-col class="text-left">
                <h3>Lettura Dati</h3>
                <hr align="left" width="200px" style="border-top: 2px solid rgba(0,0,0,0.7);">
              </b-col>
            </b-row>
            <div v-for="(label1, index) in Object.keys(request.data)" :key="index" style="margin-top: 40px;">
              <h4>{{ label1 }}</h4>
              <div v-for="(label2, indx) in Object.keys(request.data[label1])" :key="indx" style="margin-left: 15px;">
                <b>{{ label2 }}</b>: {{ request.data[label1][label2] ? request.data[label1][label2].hasOwnProperty('url') ? 'visualizza il file*' : request.data[label1][label2] : 'Nessun file caricato'}}
              </div>
            </div>
          </b-col>
          <b-col style="border-left: 1px solid rgba(0, 0, 0, 0.125)" :cols="Object.keys(get_files).length > 0 ? 6 : 4">
            <b-row id="title2">
              <b-col class="text-left">
                <h3>Lettura File</h3>
                <hr align="left" width="200px" style="border-top: 2px solid rgba(0,0,0,0.7);">
              </b-col>
            </b-row>
            <div v-if="noFile">
              Nessun File
            </div>
            <div v-else class="div_file">
              <p><i>Teni premuto il tasto ALT sopra un file e usa lo scroll del mouse per zoomare, oppure fai doppio click sul file</i></p>
              <p><i>Una volta zoomato, clicca sul file, tieni premuto e spostati a destra e sinitra per visualizzarlo dove preferisci</i></p>
              <div v-for="(label1, ind) in Object.keys(get_files)" :key="ind" style="margin-top: 40px;">
                <h4 v-if="!isEmpty(get_files[label1])">{{ label1 }}</h4>
                <div v-for="(label2, i) in Object.keys(get_files[label1])" :key="i" style="margin-top: 15px;">
                  <b-button v-b-toggle="'collapse-'+(ind+i)" variant="info">{{ label2 }}</b-button>
                  <b-collapse :id="'collapse-'+(ind+i)" class="mt-2">
                    <a :href="config.backend_url+get_files[label1][label2].url" target="_blank">vai al file</a>
                    <!-- <b-img v-if="isImage(config.backend_url+get_files[label1][label2].url)" :src="config.backend_url+get_files[label1][label2].url" fluid alt="Responsive image"></b-img> -->
                    <div v-if="isImage(config.backend_url+get_files[label1][label2].url)">
                      <IMGComponent :id="'div_img_'+ind+'_'+i" :src="config.backend_url+get_files[label1][label2].url" />
                    </div>
                    <div v-else>
                      <PDFComponent :id="'div_pdf_'+ind+'_'+i" :url="config.backend_url+get_files[label1][label2].url"></PDFComponent>
                    </div>
                  </b-collapse>
                </div>
                <!-- <span style="margin-left: 15px;">
                  <b>{{ file.label2 }}</b>
                </span>
                <b-img :src="config.backend_url+file.url" fluid alt="Responsive image"></b-img> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import config from '@/config'
import PDFComponent from '@/components/PDF'
import IMGComponent from '@/components/ImgCustom'
export default {
  name: 'my-name',
  props: ['request'],
  data() {
    return {
      hasLoaded: true,
      error: null,
      success: false,
      config: null,
      noFile: true,
      pdf: {
        src: null,
        numPages: null
      }
    }
  },
  components: {
    PDFComponent,
    IMGComponent
  },
  created() {
    this.config = config
  },
  computed: {
    get_files: function() {
      let files = {}
      if (this.request) {
        for (let i = 0; i<Object.keys(this.request.data).length; i++) {
          let item = Object.keys(this.request.data)[i];
          files[item] = {}
          for (let y = 0; y<Object.keys(this.request.data[item]).length; y++) {
            let item2 = Object.keys(this.request.data[item])[y];
            if (this.request.data[item][item2]) {
              if (this.request.data[item][item2].hasOwnProperty('url')) {
                // files.push({ label1: item, label2: item2, original_name: this.request.data[item][item2].original_name, url: this.request.data[item][item2].url })
                files[item][item2] = { label1: item, label2: item2, original_name: this.request.data[item][item2].original_name, url: this.request.data[item][item2].url }
                this.noFile = false
              }
            }
          }
        }
      }
      return files
    }
  },
  mounted() {
    console.log('request', this.request)
  },
  methods: {
    isEmpty(obj) {
      for(var key in obj) {
        if(obj.hasOwnProperty(key))
          return false;
      }
      return true;
    },
    isImage(url) {
      let ext = url.split(".")
      ext = ext[ext.length-1]
      if (ext === 'pdf' || ext === 'PDF')
        return false
      else
        return true
    }
  }
}
</script>

<style scoped>
  .div_file {
    overflow-y: auto;
    max-height: 100vh;
  }
</style>