<template>
  <div id="request">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <b-container v-if="error" fluid class="content" style="margin-top:100px;">
      <div class="col-sm-12" style="border: 1px solid red; padding :20px; border-radius: 4px;">
        <span style="color:red;" class="col-sm-12 text-center">
          <span v-html="error"></span>
        </span>
      </div>
    </b-container>
    <b-container fluid class="main" v-if="request">
      <b-row id="title">
        <b-col md="3" sm="10" class="text-left">
          <h3>Richiesta</h3>
          <hr align="left" width="65%" style="border-top: 2px solid rgba(0,0,0,0.7);">
        </b-col>
      </b-row>
      <b-card-group deck>
        <b-card :bg-variant="request.status.color" :text-variant="request.status.color === 'light' ? '' : 'white'" :header="'Stato: '+request.status.label" :class="'text-left mt-3'">
          <b-row>
            <b-col sm="8">
              <b-card-text><i>{{ request.benefit.name }}</i></b-card-text>
              <b-card-text>Data di creazione: {{ convertDate(request.createdAt) }}</b-card-text>
              <b-card-text>Ultima data di modifica: {{ convertDate(request.updatedAt) }}</b-card-text>
              <b-card-text>Data di scadenza: {{ request.closing_date ? convertDate(request.closing_date) : 'Nessuna' }}</b-card-text>
            </b-col>
            <b-col sm="4">
              <b-row>
                <p><i><b>Operazioni</b></i></p>
              </b-row>
              <b-row>
                <!-- <p v-if="request.status.id === 1 || request.status.id === 2" class="retreat" @click="showModal(request)"><b-icon-trash></b-icon-trash> Ritira richiesta</p> -->
                <p v-if="($store.state.user.user.role_id === 3 || $store.state.user.user.role_id === 4) && (request.status.id === 1 || request.status.id === 2)" class="action" @click="showModal(request, 'retreat')"><b-icon-trash></b-icon-trash> Ritira richiesta</p>
              </b-row>
              <b-row>
                <p v-if="($store.state.user.user.role_id === 5) && (request.status.id === 2)" class="action" @click="showModal(request, 'draft')"><b-icon-pencil></b-icon-pencil> Metti in bozze</p>
              </b-row>
              <b-row>
                <p v-if="($store.state.user.user.role_id === 5) && (request.status.id === 2)" class="action" @click="showModal(request, 'pre-approve')"><b-icon-check2></b-icon-check2> Pre approva</p>
              </b-row>
              <b-row>
                <p v-if="($store.state.user.user.role_id === 5) && (request.status.id === 4)" class="action" @click="showModal(request, 'approve')"><b-icon-check2-all></b-icon-check2-all> Approva</p>
              </b-row>
              <b-row>
                <p v-if="($store.state.user.user.role_id === 5) && (request.status.id === 2 || request.status.id === 4 || request.status.id === 5)" class="action" @click="showModal(request, 'reject')"><b-icon-x-circle></b-icon-x-circle> Rifiuta</p>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-card-group>
      <b-row id="request_history" class="mt-3" v-if="$store.state.user.user.role_id === 5">
        <b-col>
          <b-button v-b-toggle.request_history-collapse variant="primary">Storico Richieste</b-button>
          <b-collapse id="request_history-collapse" class="mt-2">
            <b-card>
              <p class="card-text text-left"><b>Storico delle richieste di {{ request.user.last_name + ' ' + request.user.first_name }}, oltre a quella in esame in questa pagina</b></p>
              <div v-if="request_history.length > 0" class="text-left">
                <b-card :header="req_hist.benefit.name" v-for="(req_hist, index_hist) in request_history" :key="index_hist" class="mt-2">
                  <b-card-text><i><b>STATO: </b></i> <span :style="{'color': getStatusColor(req_hist.status_id)}">{{ req_hist.status.label }}</span></b-card-text>
                  <b-card-text>Data di creazione: {{ convertDate(req_hist.createdAt) }}</b-card-text>
                  <b-card-text>Ultima data di modifica: {{ convertDate(req_hist.updatedAt) }}</b-card-text>
                  <b-card-text>Data di scadenza: {{ req_hist.closing_date ? convertDate(req_hist.closing_date) : 'Nessuna' }}</b-card-text>
                  <b-card-text v-if="req_hist.report_id && $store.state.user.user.role_id === 5">Report <b-icon-check-square></b-icon-check-square></b-card-text>
                  <!-- <b-card-text>Importo: <b><u>{{ req_hist.data.hasOwnProperty('importo') ? req_hist.data.importo.importo : 'Ancora nessun importo richiesto' }}</u></b></b-card-text> -->
                </b-card>
              </div>
              <div v-else class="text-left">
                <i>Nessuna richiesta oltre a quella in esame in questa pagina</i>
              </div>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>
      <b-tabs content-class="mt-3" class="mt-3">
        <b-tab title="Modulistica" active>
          <b-card-group deck>
            <b-card v-if="request" bg-variant="light" :header="'Dettagli richiesta: '+request.benefit.name" :class="'text-left'">
              <b-row v-if="this.$store.state.user.user.role_id !== 5">
                <b-col sm="8">
                  <EditRequest :request="request" />
                </b-col>
                <b-col sm="4 status_hist">
                  <StatusHistory :request="request" />
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col>
                  <ReadRequest :request="request" />
                </b-col>
              </b-row>
            </b-card>
          </b-card-group>
        </b-tab>
        <b-tab title="Descrizione">
          <b-card-group deck>
            <b-card v-if="request" bg-variant="light" :header="'Descrizione della richiesta: '+request.benefit.name" :class="'text-left'">
              <b-row>
                <b-col>
                  <span class="text-left" v-html="request.benefit.description"></span>
                </b-col>
              </b-row>
            </b-card>
          </b-card-group>
        </b-tab>
      </b-tabs>
      <b-modal ref="retreat-request" size="lg" hide-footer title="Ritira richiesta">
        <div class="d-block text-center">
          <h4>Sei sicuro di voler ritirare la tua richiesta?</h4>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="retreatRequest(request.id, 'cancel')">Annulla Richiesta</b-button>
        <b-button v-if="request.status_id === 2" class="mt-2" variant="outline-secondary" block @click="retreatRequest(request.id, 'draft')">Metti in bozza</b-button>
      </b-modal>

      <b-modal
        id="modal-request"
        ref="modal-request"
        title="Aggiornamento Stato"
        @hidden="resetModal"
        @hide="resetModal"
        @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <div>
            <p>
              Stai passando lo stato della richiesta a {{ action === 'draft' ? 'Bozza' : action === 'pre-approve' ? 'Pre Approvata' : action === 'approve' ? 'Approvata' :  action === 'reject' ?  'Rifiutata' : ''}}
            </p>
          </div>
          <b-form-group
            :state="modal_form.status_note"
            label="Scrivi una nota"
            label-for="note"
            invalid-feedback="Lascia una nota"
          >
            <b-form-input
              id="note"
              v-model="modal_form.note"
              :state="modal_form.status_note"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import RequestService from '@/services/RequestService'
import EditRequest from '@/components/Request/EditRequest'
import StatusHistory from '@/components/Request/StatusHistory'
import ReadRequest from '@/components/Admin/ReadRequest'
export default {
  name: 'request',
  data() {
    return {
      hasLoaded: false,
      error: null,
      request: null,
      request_history: [],
      action: '',
      modal_form: {
        note: '',
        status_note: null
      }
    }
  },
  components: {
    EditRequest,
    StatusHistory,
    ReadRequest
  },
  created () {
    this.$eventHub.$on('request_saved', (request) => {
      this.request = request
      return
    })
  },
  beforeDestroy() {
    this.$eventHub.$off('request_saved')
  },
  computed: {
    getStatusHistorySorted: function() {
      return this.request.status_history.sort(function(a, b) {
        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      })
    }
  },
  async mounted() {
    try {
      const request = await RequestService.getRequest(this.$route.params.id)
      console.log('request', request)
      this.request = request.data
      if (request.data && this.$store.state.user.user.role_id === 5) {
        var filters = {
          company: null,
          status: null,
          date: {
            from: null,
            to: null
          },
          user: request.data.user.id
        }
        const request_history = await RequestService.getRequestByInstitution(this.$store.state.user.user.institution_id, filters)
        console.log('req history', request_history)
        this.request_history = request_history.data.filter(el => el.id !== request.data.id )
      }
    } catch(err) {
      console.log('err', err)
      this.error = 'Link non valido'
    } finally {
      this.hasLoaded = true
    }
  },
  methods: {
    convertDate(inputFormat) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(inputFormat)
      return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    },
    convertDate2(date) {
      var date = new Date(date),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hour = ("0" + date.getHours()).slice(-2),
      min = ("0" + date.getMinutes()).slice(-2);
      return [day, mnth, date.getFullYear()].join("/") + ' ' + [hour, min].join(":");
    },
    showModal(request, action) {
      this.action = action
      if (action === 'retreat') {
        this.$refs['retreat-request'].show()
      } else {
        this.$refs['modal-request'].show()
      }
    },
    async retreatRequest(request_id, method) {
      this.hasLoaded = false
      this.$refs['retreat-request'].hide()
      try {
        console.log(request_id, method)
        let ris = await RequestService.retreatRequest(request_id, method)
        if(ris) {
          console.log('ris', ris)
          this.success = true
          this.request = ris.data
        }
      } catch(err) {
        console.log('err', err)
        this.error = 'An error occured retreating request'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.success = false
        }, 3000)
      }
    },
    resetModal() {
      this.action = ''
      this.modal_form = {note: '', status_note: null}
    },
    handleOk(bvModalEvt) {
      this.hasLoaded = false
      bvModalEvt.preventDefault()
      console.log(this.action)
      this.handleSubmit(this.action, this.request.id)
    },
    async handleSubmit(action, request_id) {
      try {
        let status_id = action === 'draft' ? 1 : action === 'pre-approve' ? 4 : action === 'approve' ? 5 : action === 'reject' ? 6 : 2
        const ris = await RequestService.updateStatusrRequest(request_id, {status_id: status_id, note: this.modal_form.note})
        if(ris) {
          console.log('ris', ris)
          this.success = true
          this.request = ris.data
        }
      } catch (err) {
        console.log('err',err)
        this.error = 'An error occurred'
      } finally {
        this.hasLoaded = true
        setTimeout(() => {
          this.error = null
          this.success = false
        }, 3000)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-request')
        })
      }
    },
    getStatusColor(status_id) {
      switch(status_id) {
        case 1:
          return '#333!important'
        case 2:
          return '#ffc107!important'
        case 3:
          return '#6c757d!important'
        case 4:
          return '#17a2b8!important'
        case 5:
          return '#28a745!important'
        case 6:
          return '#dc3545!important'
        default:
          return 'black'
      }
    },
    getStatusHistoryFormatted(errors) {
      let array = []
      for (let i = 0; i<Object.keys(errors).length; i++) {
        var item = Object.keys(errors)[i]
        let obj = {}
        obj.message = item
        obj.errors = []
        for (let y = 0; y<Object.keys(errors[item]).length; y++) {
          var item2 = Object.keys(errors[item])[y]
          let obj2 = {}
          obj2.message = errors[item][item2].message
          obj.errors.push(obj2)
        }
        array.push(obj)
      }
      console.log('array', array)
      return array
    },
    getStatusColor(status_id) {
      switch(status_id) {
        case 1:
          return '#333!important'
        case 2:
          return '#ffc107!important'
        case 3:
          return '#6c757d!important'
        case 4:
          return '#17a2b8!important'
        case 5:
          return '#28a745!important'
        case 6:
          return '#dc3545!important'
        default:
          return 'black'
      }
    },
  }
}
</script>

<style scoped>
  @media screen and (max-width: 992px) {
    .status_hist {
      margin-top: 15px;
    }
  }
  .retreat {
    cursor: pointer;
    transition: .3s;
  }
  .retreat:hover {
    color: #f91942;
  }
</style>