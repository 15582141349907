<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'placeholder', ],
    methods: {
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true && this.model === null ) { return check; }
            if ( check === true ) {
                check = this._validate_max_length();
            }
            if ( check === true ) {
                check = this._validate_min_length();
            }
            return check;
        },
        _validate_max_length() {
            if ( !this.maxlength ) { return true; }
            if ( this.model.length > this.maxlength ) {
                this.error_component = true;
                this.error_message = `lunghezza massima ${this.maxlength} caratteri`;
                return false;
            }
            return true;
        },
        _validate_min_length() {
            if ( !this.minlength ) { return true; }
            if ( this.model.length < this.minlength ) {
                this.error_component = true;
                this.error_message = `lunghezza minima ${this.minlength} caratteri`;
                return false;
            }
            return true;
        }
    },
    computed: {
        _model: {
            get : function() {
                if ( this.search ) {
                    if ( this.search === 'contains_before_after' ) {
                        var res = this.model.replace(/^\|/, '')
                                            .replace(/\|$/, '');
                        return res;
                    }
                    return res;
                }
                return this.model;
            },
            set : function (value) {
                this.model = value;
            }
        },
    }
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}</label>
        <b-input
            v-on:keyup.enter="enter_key"
            ref="input"
            type="text"
            :size="input_class"
            v-bind:class="{ 'is-invalid': error_component }"
            :placeholder="get_placeholder()"
            :aria-label="name"
            :aria-describedby="name"
            :id="'bnv-form-' + name"
            v-model="_model"
            :disabled="disabled"
            :readonly="_readonly"
        />
        <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>



