import Api from '@/services/Api'
import MultipartApi from '@/services/MultipartApi'

export default {
  getAllRequest() {
    return Api().get('/model/request?include=true')
  },
  retreatRequest(request_id, method) {
    return Api().patch(`/model/request/retreat/${request_id}?retreat=${method}&include=true`)
  },
  getRequest(request_id) {
    return Api().get(`/model/request/${request_id}?&include=true`)
  },
  getRequestByInstitution(institution_id, filters) {
    var url = `/model/request/institution/${institution_id}?&include=true&order=updatedAt DESC`
    filters.company ? url+= `&user.company_id=${filters.company}` : url+=''
    filters.status ? url+= `&status_id=${filters.status}` : url+=''
    filters.user ? url+= `&user_id=${filters.user}` : url+=''
    if (filters.date.from || filters.date.to) {
      if (filters.date.from && filters.date.to) {
        url+= `&createdAt=${filters.date.from}|${filters.date.to}`
      }
    }
    console.log('Url richiesta', url)
    return Api().get(url)
  },
  updateStatusrRequest(request_id, payload) {
    return Api().patch(`/model/request/update_status/${request_id}?&include=true`, payload)
  },
  createRequest(data) {
    return Api().post(`/model/request?include=true`, data)
  },
  saveRequest(data, request_id, multipart) {
    console.log('multipart RS', multipart)
    return multipart ? 
        MultipartApi().patch(`/model/request/save/${request_id}?include=true`, data)
      : Api().patch(`/model/request/save/${request_id}?include=true`, data)
  },
  publishRequest(data, request_id, multipart) {
    console.log('multipart RS', multipart)
    return multipart ?
        MultipartApi().patch(`/model/request/publish/${request_id}?include=true`, data)
      : Api().patch(`/model/request/publish/${request_id}?include=true`, data)
  },
  gerReportRequests(institution_id) {
    return Api().get(`/model/request/report/${institution_id}?include=true`)
  },
  getCountRequest(institution_id, status_id) {
    return Api().post(`/model/request/count/`, {institution_id, status_id})
  }
}