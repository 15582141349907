import Api from '@/services/Api'

export default {
  getReport(report_id) {
    return Api().get(`model/report/${report_id}?include=true`)
  },
  createReport(report) {
    return Api().post(`model/report?include=true`, report)
  },
  getReportsByInstitution(institution_id) {
    return Api().get(`model/report/institution/${institution_id}?include=true`)
  }
}